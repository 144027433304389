.table-jobs {
  overflow-x: auto;
}

.table-jobs table {
  width: 100%;
  table-layout: fixed;
}

.table-jobs th,
.table-jobs td {
  white-space: unset;
}

.highlight b {
  background-color: #FDC707;
}

.ui.celled.basic.table.standard tr td {
  font-size: 14px !important;
  padding: 10px !important;
}

.job-status-val {
  margin: 0;
  text-align: center;
}

.job-status-val-Completed {
  color: #318C79;
  font-weight: 800;
}

.job-status-val-Exception {
  color: #E42E2E;
  font-weight: 800;
}

.lastUpdated {
  text-align: center !important;
}

.lastUpdated strong {
  font-weight: bold;
}

.lastUpdated p {
  margin-bottom: 1px;
}

.load-more-job-btn {
  border: 0 !important;
  background: transparent !important;
  text-decoration-line: underline !important;
  text-decoration-color: #0095E3 !important;
  text-decoration-thickness: 2px !important;
  font-weight: 800 !important;
  font-size: 16px !important;
  cursor: pointer !important;
  box-shadow: 0 !important;
  color: #0095E3 !important;
  padding-bottom: 5px !important;
}

.load-more-job-btn .ui.loading.button:before {
  border-color: #0095E3 !important;
}

.date-range-picker-wrapper .CalendarMonthGrid ,.date-range-picker-wrapper .CalendarMonth,
.date-range-picker-wrapper .CalendarDay__default,
.date-range-picker-wrapper .DayPicker__horizontal,
.date-range-picker-wrapper .DayPickerNavigation_button {
  background-color: #f4f4f4;
}

.date-range-picker-wrapper .DayPickerNavigation_button__default{
  border: 0;
}

.date-range-picker-wrapper td {
  color: #234E6E;
}

.date-range-picker-wrapper  .CalendarDay__selected, 
.date-range-picker-wrapper  .CalendarDay__selected:active, 
.date-range-picker-wrapper  .CalendarDay__selected:hover {
  background-color: #1B97E0 !important;
  color: #fff !important;
  border-radius: 50%;
}

.date-range-picker-wrapper .CalendarDay__default {
  border:  0;
}

.date-range-picker-wrapper .CalendarDay__selected_span {
  background-color: rgba(27, 151, 224, 0.1);
}

.date-range-picker-wrapper .DayPickerKeyboardShortcuts_buttonReset {
  display: none;
}

.date-range-picker-wrapper .DateInput input{
   font-size: 16px !important;
}

.date-range-picker-wrapper .CalendarDay__blocked_out_of_range {
  color: #ff6956;
  border: 1px solid transparent;
  cursor: not-allowed;
}