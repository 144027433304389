
/*******************************
         Site Overrides
*******************************/

// base
.ui.grid>.column:not(.row),
.ui.grid>.row>.column {

  &.snug-right {
    margin-bottom: 1rem;
  }

  &.snug-both {
    margin-bottom: 1rem;
  }
}

@media (min-width: @tabletBreakpoint) {
  .ui.grid>.column:not(.row),
  .ui.grid>.row>.column {

    &.snug-left {
      padding-left: 0.5rem;
    }

    &.snug-right {
      padding-right: 0.5rem;
      margin-bottom: 0;
    }

    &.snug-both {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      margin-bottom: 0;
    }

  }
  .ui.segment,
  .ui.divider,
  p {
    &.make-flat {
      margin-left: -0.5em;
      margin-right: -0.5em;
    }
  }
}
