.image-slider {
  display: grid;
  padding: 10px;
  gap: 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.image-slider__image {
  position: relative;
  margin-right: 20px;
}

.image-slider__image svg {
  position: absolute;
  right: 0;
  background: #0d2b3a;
  margin: 5px;
  border-radius: 5px;
  padding: 4px;
  color: #fff;
}

.image-slider__image:last-child {
  margin-right: 0;
}

.image-slider__image img {
  height: 200px;
  width: 200px;
  height: auto;
  cursor: pointer;
}

.image-slider__header {
  flex: 1;
  text-align: right;
  position: absolute;
  right: 0;
}

.image-slider__modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  z-index: 10;
}

.image-slider__modal svg {
  cursor: pointer;
}

.modal-content {
  position: relative;
  display: flex;
  gap: 25px
}

.modal-content {
  position: relative;
  justify-content: center;
  display: flex;
  flex: 10
}

.modal-content img {
  border-radius: 15px; 
  object-fit: contain;
  display: block;
}

.modal-navigation {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-navigation button {
  margin: 0 5px;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
}

.image-slider-wrapper {
  display: flex;
  width: 8  0%;
  height: 100%;
  flex-direction: column;
  padding: 20px 100px;
  z-index: 10;
}

.slider-arrow-disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.more__slide {
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 20px;
  background: #e7f5fc;
  color: #1c97e0;
  font-weight: bold;
  cursor: pointer;
  width: 200px;
  text-decoration-line: underline;
  max-height: 150px;
}

.backdrop-shadow {
  background-color: #ffffff;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 5;
  opacity: 0.1;
}