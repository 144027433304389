
/*******************************
             Sidebar
*******************************/

.pushable {
  height: 100%;

  & > .pusher {
    height: 100%;
  }
}

.ui.visible.left.overlay.sidebar {
  width: 100%;
  box-shadow: 0 2px 74px 0 rgba(0, 0, 0, 0.21);
  transition: transform .25s ease,-webkit-transform .25s ease;
}

.ui.left.overlay.sidebar {
  top: 126px;
  transition: transform .25s ease,-webkit-transform .25s ease;
  background: white;
}

// base
.address-result {
  background: #F2F2F2;
  width: 100%;

  // pulse
  .pulse {
    position: absolute;
    right: 50px;
    top: 50%;
    margin-top: -2px;
    background: #FFFFFF;
    width: 13px;
    height: 13px;
    border: 4px solid #0095E3;
    border-radius: 100px;
    animation-name: locate;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }

  // headings
  h4.ui.header {
    color: #0A5372;
    font-size: 17px;

    &:first-child {
      margin-top: 0;
    }
  }

  // summary
  .summary {
    margin: 0 25px 36px 25px;
    padding-top: 20px;
    border-bottom: solid 5px rgba(151, 151, 151, 0.1);

    .ui.basic.table tbody tr {
      &:first-child {
        td {
          padding-bottom: 18px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          padding-top: 0;

          &:last-child {
            color: #DB6060;
          }
        }
      }

      td {
        border-top: none;
        font-weight: bold;
        padding: 16px .78571429em;

        &:first-child {
          color: #004B6D;
        }

        &.action-link {
          a {
            color: #5F5F5F;

            &:hover,
            &:active,
            &:focus {
              color: darken(#5F5F5F, 3%);
            }
          }

          img {
            height: 19px;
          }
        }
      }
    }
  }

  // active tickets
  .active-tickets {
    margin-bottom: 45px;
    
    .ui.header {
      margin-bottom: 27px;
    }

    .ticket {
      background: #FFFFFF;
      position: relative;
      padding: 18px;
      margin-bottom: 15px;
      box-shadow: 0 2px 6px 0 rgba(0,0,0,0.09);
      span {
        display: block;
      }

      .number,
      .title {
        font-size: 16px;
        margin-bottom: 5px;
        font-weight: bold;
      }

      .number {
        color: #0095E3;
      }

      .title {
        color: #434343;
      }

      .pick-up {
        font-size: 13px;
        color: #909090;
      }

      &:last-child { margin-bottom: 0; }
    }
  }

  // waste systems
  .waste-tabs .header {
    padding: 0 25px;
    margin-bottom: 18px;
  }

  // tabs menu
  .ui.tabular.menu {
    border: none;

  &.attached {
      box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.09) !important;
      position: relative;
      z-index: 9;
  }    
    a.item {
      background: #fff;
      font-size: 13px;
      height: 60px;
      border-right: 1px solid #d8d8d8;
      border-bottom: 1px solid #d8d8d8;
      border-top: 1px solid #d8d8d8;
      border-left: 0;
      flex-basis: 33.333333%;
      justify-content: center;
      padding: 10px;
      color: #004b6d;
      font-weight: bold;

      .ui.label {
        background: transparent;
        color: #0095e3;
        border: solid 1px #97979720;
        padding: 0.375em 0.75em;
        border-radius: 40px;
        font-weight: 900;
      }

      &.active {
        background: #FFFFFF;
        border-radius: 0 !important;
        margin-bottom: 0;
        border-bottom: 4px solid #0095e3;
        padding: 10px 10px 7px 10px;
        color: #0095e3;
        &:focus,
        &:hover,
        &:active {
          background: #FFFFFF;
        }
      }

      &:last-child {
        border-right: none;
      }

      &:focus,
      &:hover,
      &:active {
        background: #fff;
      }
    }
  }

  // tab
  .ui.segment.tab {
    border: none;
    padding: 25px;

    &.transparent-background {
      background: transparent;
    }
    
    &.grey-background {
      background: #eeeff0;
    }

    .notice {
      padding: 18px;
      box-shadow: 0 2px 0 0 #dbdbdb, 0 2px 14px 0 rgba(0, 0, 0, 0.14);
      margin-bottom: 25px;

      &.alert {
        .title {
          margin-bottom: 25px;

          span {
            display: block;
            margin-left: 45px;
            margin-top: 5px;
            color: #909090;
            font-size: 13px;
          }
        }

        .image-container {
          position: relative;

          &:after {
            position: absolute;
            content: '\E806';
            font-family: 'fontello';
            color: #FFFFFF;
            right: 8px;
            bottom: 8px;
          }

          .ui.image:hover {
            cursor: pointer;
          }
        }

        &.damaged {
          box-shadow: 0 2px 0 0 #000000, 0 2px 13px 0 rgba(0, 0, 0, 0.1);
        }

        &.overfilled {
          box-shadow: 0 2px 0 0 #8D43FF, 0 2px 13px 0 rgba(0, 0, 0, 0.1);
        }

        &.missed {
          box-shadow: 0 2px 0 0 #F5292A, 0 2px 13px 0 rgba(0, 0, 0, 0.1);
        }

        &.other {
          box-shadow: 0 2px 0 0 #0095E3, 0 2px 13px 0 rgba(0, 0, 0, 0.1);
        }

        &.contaminated {
          box-shadow: 0 2px 0 0 #B0D500, 0 2px 13px 0 rgba(0, 0, 0, 0.1);
        }
      }

      &.been {
        .truck {
          vertical-align: sub;
        }

        i.green.icon {
          position: absolute;
          left: 15px;
          background: #FFFFFF;
          box-shadow: none;
          width: 1.25em !important;
          height: 1.25em !important;
          padding: .15em .2em !important;
        }
      }

      &.no-activity {
        .title {
          margin-bottom: 20px;
        }

        .truck {
          opacity: 0.5;
        }
      }

      .title {
        position: relative;

        .ui.header {
          padding-right: 0;
          font-size: 16px;
          font-weight: 700;
          display: block;
          margin: 0;
          color: #434343;
          display: inline-block;
        }

        .truck {
          margin-left: 10px;
          transform: rotate(145deg);
        }

        .sub.header {
          color: #909090;
          margin-top: 2px;
        }
      }

      p { font-size: 13px; }
    }
  }
}

// tablet up
@media (min-width: @tabletBreakpoint) {

  .ui.left.overlay.sidebar {
    top: 0;
  }

  .address-result & > div {
    padding-top: 60px !important;
  }

  .ui.visible.left.overlay.sidebar {
    width: 383px;
  }
}

// pulse animation
@-webkit-keyframes locate {
  0% {
    -webkit-box-shadow:
      0 0 0 0 hsla(360, 100%, 100%, 1),
      0 0 0 0 hsla(201, 100%, 45%, 0.3),
      0 0 0 0 hsla(360, 100%, 100%, 1),
      0 0 0 0 hsla(201, 100%, 45%, 0.3),
      0 0 0 0 hsla(360, 100%, 100%, 1),
      0 0 0 0 hsla(201, 100%, 45%, 0.2);
  }
  70% {
    -webkit-box-shadow:
      0 0 0 6px hsla(360, 100%, 100%, 0),
      0 0 0 9px hsla(201, 100%, 45%, 0),
      0 0 0 12px hsla(360, 100%, 100%, 0),
      0 0 0 14px hsla(201, 100%, 45%, 0),
      0 0 0 19px hsla(360, 100%, 100%, 0),
      0 0 0 20px hsla(201, 100%, 45%, 0);
  }

  100% {
    -webkit-box-shadow:
      0 0 0 0 hsla(360, 100%, 100%, 0),
      0 0 0 0 hsla(201, 100%, 45%, 0),
      0 0 0 0 hsla(360, 100%, 100%, 0),
      0 0 0 0 hsla(201, 100%, 45%, 0),
      0 0 0 0 hsla(360, 100%, 100%, 0),
      0 0 0 0 hsla(201, 100%, 45%, 0);
  }
}

@keyframes locate {
  0% {
    -moz-box-shadow:
      0 0 0 0 hsla(360, 100%, 100%, 1),
      0 0 0 0 hsla(201, 100%, 45%, 0.3),
      0 0 0 0 hsla(360, 100%, 100%, 1),
      0 0 0 0 hsla(201, 100%, 45%, 0.3),
      0 0 0 0 hsla(360, 100%, 100%, 1),
      0 0 0 0 hsla(201, 100%, 45%, 0.2);

    box-shadow:
      0 0 0 0 hsla(360, 100%, 100%, 1),
      0 0 0 0 hsla(201, 100%, 45%, 0.3),
      0 0 0 0 hsla(360, 100%, 100%, 1),
      0 0 0 0 hsla(201, 100%, 45%, 0.3),
      0 0 0 0 hsla(360, 100%, 100%, 1),
      0 0 0 0 hsla(201, 100%, 45%, 0.2);
  }

  70% {
    -moz-box-shadow:
      0 0 0 6px hsla(360, 100%, 100%, 0),
      0 0 0 9px hsla(201, 100%, 45%, 0),
      0 0 0 12px hsla(360, 100%, 100%, 0),
      0 0 0 14px hsla(201, 100%, 45%, 0),
      0 0 0 19px hsla(360, 100%, 100%, 0),
      0 0 0 20px hsla(201, 100%, 45%, 0);

    box-shadow:
      0 0 0 6px hsla(360, 100%, 100%, 0),
      0 0 0 9px hsla(201, 100%, 45%, 0),
      0 0 0 12px hsla(360, 100%, 100%, 0),
      0 0 0 14px hsla(201, 100%, 45%, 0),
      0 0 0 19px hsla(360, 100%, 100%, 0),
      0 0 0 20px hsla(201, 100%, 45%, 0);
  }

  100% {
    -moz-box-shadow:
      0 0 0 0 hsla(360, 100%, 100%, 0),
      0 0 0 0 hsla(201, 100%, 45%, 0),
      0 0 0 0 hsla(360, 100%, 100%, 0),
      0 0 0 0 hsla(201, 100%, 45%, 0),
      0 0 0 0 hsla(360, 100%, 100%, 0),
      0 0 0 0 hsla(201, 100%, 45%, 0);

    box-shadow:
      0 0 0 0 hsla(360, 100%, 100%, 0),
      0 0 0 0 hsla(201, 100%, 45%, 0),
      0 0 0 0 hsla(360, 100%, 100%, 0),
      0 0 0 0 hsla(201, 100%, 45%, 0),
      0 0 0 0 hsla(360, 100%, 100%, 0),
      0 0 0 0 hsla(201, 100%, 45%, 0);
  }
}
