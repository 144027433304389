
/*******************************
            Dropdown
*******************************/

.field {
  .ui.selection.dropdown {
    border-width: 3px;
    line-height: 1.21428571em;
    padding: 1.32142857em 2.28571429rem;
    font-size: 1.14285714em;

    .menu>.item {
      line-height: 1.21428571em;
      padding: 1.32142857em 2.28571429rem !important;
      font-size: 16px;
    }

    i.icon {
      float: right;
      color: #0095E3;
      font-size: 24px;
      height: inherit;
    }

    &.active {
      i.icon:before {
        content: "\f106";
      }
    }
  }
}

.ui.selection.dropdown .menu {
  margin: 0;
  min-width: calc(100%);
  width: calc(100%);
  max-height: none !important;
}

.ui.menu {
  .ui.dropdown.user-dropdown {
    border-radius: 0;
    font-size: 14px;
    margin: 0 !important;

    // trucks
    &.trucks {
      padding-left: 50px !important;

      &:before {
        font-family: 'fontello';
        content: "\e808";
        position: absolute;
        left: 20px;
      }

      .item > img {
        max-height: none !important;
        height: 1.75em !important;
        margin-right: 20px;
      }
    }

    // alerts
    &.alerts {
      padding-left: 50px;

      &:before {
        font-family: 'fontello';
        content: "\e807";
        position: absolute;
        left: 20px;
      }
    }

    i.dropdown.icon { display: none; }

    &.active {
      box-shadow: none;

      i.icon.angle.down:before {
        content: "\f106";
        font-weight: bold;
      }
    }

    &.trucks .menu .item > img {
      transform: rotate(135deg);
    }

    .menu {
      background: @primaryColor;
      border: none;
      border-radius: 0;

      .item {
        color: #FFFFFF !important;
        padding-top: 24px !important;
        padding-bottom: 24px !important;
        border-bottom: 1px solid #00354E;
        border-top: none !important;

        & > .image,
        & >img {
          max-height: 1em;
        }

        &.active {
          background: #005A84 !important;
          font-weight: normal !important;

          i.angle.right { display: none; }

          &:after {
            content: "\f058";
            font-family: Icons;
            position: absolute;
            right: 20px;
            font-size: 1.5em;
          }
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}
