.loading-indicator-popup {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  display: flex;
  background-color: #ffffff;
  z-index: 100;
  padding-top: 20% !important;
  height: 100%;
}

.job-view-container {
  margin-top: 180px;
}

.job-view-crn-title {
  height: 130px;
  background-color: #091b24;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 40px;
  margin-top: 50px;
  position: fixed;
    z-index: 1;
    right: 0;
    left: 0;
}

.job-view-crn-title label {
  color: #fff;
  font-size: 40px;
  font-weight: 800;
}

.job-view-crn-title label span {
  color: #1b97e0;
}

.job-view-crn-title p {
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  margin-top: 15px;
}

.job-view-crn-title div {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.job-view-crn-title div button {
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border-radius: 50px;
  border: 1px solid #004B6D;
  width: 167px;
  height: 45px;
  background: #004B6D;
  color: #fff;
  font-size: 14px;
}
