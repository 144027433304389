
/*******************************
           Secondary
*******************************/

.ui.secondary.menu {
  max-height: 60px;
  position: fixed;
  left: 0;
  width: 100%;
  margin: 0;
  z-index: 9;
  padding: 0.5em 1em 0.5em 0.5em;

  .item {
    &.link {
      margin: 0;
    }

    &.link:hover,
    &.link:focus {
      color: #FFFFFF;
      outline: none;
    }
  }

  .menu-accordion {
    background: transparent;
  }

  .ui.selection.dropdown {
    border: none;
    border-radius: 5px;
    background: #004b6d;
    color: #FFFFFF;
    line-height: 2em;
    padding: 0 5px 0 12px;
    min-width: 16em;
    min-height: 1em;
    max-height: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    i.icon {
      font-size: 1.4em;
      float: right;
      &.angle {
        font-size: 1.9em;
      }
    }

    .menu {
      max-height: none !important;
    }
  }

  .ui.selection.visible.dropdown>.text:not(.default),
  .ui.default.dropdown:not(.button)>.text,
  .ui.dropdown:not(.button)>.default.text {
    color: #FFFFFF;
  }
}

@media (max-width: @tabletBreakpoint) {

  .ui.secondary.menu {
    max-height: none;
    .ui.selection.dropdown {
      width: 100%;
    }

    .right.menu {
      flex-direction: column;

      .view-map {
        i.icon {
          position: absolute;
          right: 25px;
        }
      }

      .ui.selection.dropdown,
      .status-container {
        border-bottom: 1px solid #27A5E7;
      }

      .ui.selection.dropdown {
        padding: .785714em 1.5em;
      }

      .status-container {

        .ui.form {
          .inline.fields {
            & > label {
              margin-bottom: 20px;
              display: block;
              width: 100%;
              text-align: left;
            }

            .field:last-child label {
              margin-right: 0;
            }
          }

          .inline label {
            margin-right: 30px;
          }
        }
      }
    }
  }
}

/*******************************
          MAIN MENU
*******************************/


.ui.menu .main-menu {

  a.item {
    border-bottom: 4px solid transparent;
    font-weight: bold;
  }

  a.item.active {
    background: transparent;
    border-bottom: 4px solid #0095e3;
  }
}

/*******************************
          Small Menu
*******************************/

@media (max-width: @largeMonitorBreakpoint) {
  .ui.menu .item {
    padding: 19px 25px;
  }
}

/*******************************
          Mobile Menu
*******************************/

@media (min-width: @largestLaptopBreakpoint + 1px) {
  .mobile-menu { display: none !important; }
}

@media (max-width: @largestLaptopBreakpoint) {
  .main-menu { display: none !important; }
}

.mobile-menu {
  position: initial !important;
  padding-right: 20px !important;

  .ui.dropdown {
    position: initial;

    .menu {
      top: 74px;
      width: 100%;
    }
  }
}

@media (min-width: @tabletBreakpoint) and (max-width: @computerBreakpoint) {

  .ui.secondary.menu .ui.selection.dropdown {
    min-width: 14em;
  }

  .ui.secondary.menu .right.menu .status-container {
    border-left: none;

    .ui.form {
      .inline.fields {
        & > label { display: none; }
      }

      .field:last-child label {
        margin-right: 5px;
      }

      .inline label {
        margin-right: 30px;
      }
    }
  }
}
