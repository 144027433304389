
/*******************************
         Custom Fonts
         @font-face {
  font-family: 'fontello';
  src: url("/assets/fonts/fontello.eot?10699074");
  src: url("/assets/fonts/fontello.eot?10699074#iefix") format('embedded-opentype'),
       url("/assets/fonts/fontello.woff2?10699074") format('woff2'),
       url("/assets/fonts/fontello.woff?10699074") format('woff'),
       url("/assets/fonts/fontello.ttf?10699074") format('truetype'),
       url("/assets/fonts/fontello.svg?10699074#fontello") format('svg');
}
*******************************/



[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
}

// warning icon
i.icon-warning {

  &.white-bg {
    position: relative;

    &:after {
      content: '';
      height: 10px;
      width: 3px;
      background: #fff;
      position: absolute;
      left: 7px;
      top: 3px;
      z-index: -1;
    }
  }

  &.bin_damaged {
    color: #000000;
  }

  &.bin_not_emptied {
    color: #8D43FF;
  }

  &.not_presented {
    color: #F5292A;
  }

  &.other {
    color: #0095E3;
  }

  &.contaminated {
    color: #B0D500;
  }
}

.icon-location-outline:before { content: '\e800'; } /* '' */
.icon-paper:before { content: '\e801'; } /* '' */
.icon-mobile-toggle:before { content: '\e802'; } /* '' */
.icon-check:before { content: '\e803'; } /* '' */
.icon-right:before { content: '\e804'; } /* '' */
.icon-left:before { content: '\e805'; } /* '' */
.icon-search-plus:before { content: '\e806'; } /* '' */
.icon-warning:before { content: '\e807'; } /* '' */
.icon-white_truck:before { content: '\e808'; } /* '' */
.icon-cancel:before { content: '\e809'; } /* '' */
.icon-location:before { content: '\e80a'; } /* '' */
