
/*******************************
         Label Overrides
*******************************/

.user-badge-wrapper {
  display: flex;
  align-items: center;
  font-weight: bold;
}

.ui.circular.label.user-badge {
  padding: 0.5em 0em !important;
  font-weight: 900;
  font-size: 1.15em;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

