.sort-button {
  cursor: pointer;
}

.sort-icon {
  margin-left: 0.25em;
}

.sort-ascending {
  top: -0.5em;
  position: relative;
}