
/*******************************
            Checkbox
*******************************/

// normal checks
.ui.form {
  .inline.fields {
    .field {
      padding-right: 3em;
    }
  }

  .ui.checkbox label,
  .ui.checkbox+label {
    font-size: 16px;
    font-weight: bold;
  }
}

// menu checks for tickets view
.ui.secondary.menu {

  .status-container {
    max-height: none;
    background: #004b6d;
    border-radius: 5px;
    padding-left: 30px;
  }

  .inline label {
    font-size: 14px;
    color: #FFFFFF;
    font-weight: normal;
    margin-right: 40px;
  }

  .ui.form .inline.fields {
    margin-bottom: 0;

    .field {
      padding: 0;

      &:last-child {
        label { margin-right: 20px; }
      }
    }
  }


}

  .ui.checkbox .box:before,
  .ui.checkbox label:before {
    background: #0070A8;
    border: none;
    border-radius: 3px;
    height: 19px;
    width: 19px;
  }

  .ui.checkbox label,
  .ui.checkbox+label {
    color: #FFFFFF;
  }

.ui.secondary, .active-lifts {
  .ui.checkbox input:checked~.box:after,
  .ui.checkbox input:checked~label:after {
    background: #78BE1F;
    color: #053a52;
    border-radius: 3px;
    height: 19px;
    width: 19px;
    transition: background-color 200ms;
  }
  .ui.checkbox input:checked~label:hover {
    &:after {
    background: #82dd64;
    }
  }
}

@media (min-width: @tabletBreakpoint) {
  .ui.secondary.menu .status-container {
    height: 44px;
  }
}

@media (max-width: @tabletBreakpoint) {
  // normal checks
  .ui.form {
    .inline.fields {
      .field {
        margin-bottom: 2em;
        display: block;
        width: 100%;
        padding-right: 0;
      }
    }
  }
}
