
/*******************************
        User Overrides
*******************************/

.ui.secondary.pointing.menu.tab-menu {
  position: static;
  background: none;
  border-top: none;
  border-bottom: 2px solid rgba(151, 151, 151, 0.1);

  .item {
    color: #004A6E;
    font-weight: bold;
    font-size: 22px;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-right: 35px;

    &.active {
      border-color: #008DD6;
      color: #008DD6;
    }
  }
}

.ui.attached.segment.plain-tab {
  border: none;
  padding: 50px 0;
}

.tab-subheader {
  margin-bottom: 40px !important;

  h3.ui.header {
    font-size: 22px;
    font-weight: 400;
    color: #004A6E;
    line-height: 66px;
  }

  & > .row {
    padding-top: 0;
  }
}
