/*******************************
         Accordion Overrides
*******************************/
.invoice-table__header {
  .headings {
    display: inline-block;
    color: black;
    font-weight: bold;
  }
  tr > td {
    background: white !important;
    border: none !important;
  }
}
.ui.accordion,
.ui.accordion .accordion {
  &.invoice-accordion {
    .title {
       margin: 0 0 -55px 0;
    }
    .content {
      background: #F5F5F5;
      padding: 10px 15px 0 !important;
      margin: -62.5px 0 0 0 !important;
      table {
        margin: 0px 0px 10px !important;
      }
    }
  }
}
