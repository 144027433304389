.map-bubbles {
  position: absolute;
  z-index: 3;
  display: grid;
  grid-gap: 10px;
  gap: 10px;
  right: 12px;
  min-width: 300px;
  top: 70px
}

.ui.secondary.menu {
  gap: 10px;
}

#date-picker-historical-live {
  display: inline-flex;
  grid-template-columns: 1fr ;
}


@media only screen and (max-width: 600px) {
  #date-picker-historical-live {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

.activity_area_pop_up {
  background: #225b78;
  border-radius: 10px;
  padding: 10px;
}

.activity_area-head {
  display: grid;
  grid-template-columns: 1fr 8fr;
  color: #fff;
}

.set_wastestream {
  margin-top: 5px;
}

.set_wastestream a {
  color: #12b7ef;
  border-bottom: 1px solid #12b7ef;
  margin-left: 5px;
}

.set_wastestream a:hover {
  color: #12b7ef;
}

.bin-icon {
  width: 24px;
  height: 24px;
  display: inline-block;
  margin: 0 7px 0 0;
  float: left;
  border-radius: 100%;
}

.bin-icon img {
  position: absolute;
  left: 8px;
}

.recycle {
  background: #e8aa0c;
}

.glass {
  background: #9541fe;
}

.refuse {
  background: #dc0028;
}

.hardwaste {
  background: #ff5c00;
}

.greenwaste,
.green,
.organics {
  background: #42c332;
}

.street_litter {
  background: #000080;
}

@media only screen and (max-device-width: 768px) {
  .map-bubbles {
    top: 145px
  }
}

@media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait){
  .map-bubbles {
    top: 70px
  }
}