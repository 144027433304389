.live-job-detail-popup {
  position: fixed;
  min-height: 300px;
  width: 352px;
  background: #fff;
  right: 40px;
  z-index: 1;
  top: 10%;
  border-top: 6px solid #004b6d;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding-bottom: 20px;
}

.controls {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-around;
  align-items: center;
  gap: 10px;
  padding: 15px;
  padding-right: 20px;
  padding-left: 20px;
  border-bottom: 1px solid #ccc;
}

.controls .control-section {
  display: flex;
  gap: 5px;
  align-items: center;
}

.control-section-marker-icon svg {
  height: 35px;
  width: 35px;
}

.controls button {
  width: 32px;
  height: 32px;
  background-color: #1b97e0;
  justify-content: center;
  align-items: center;
  display: flex;
  border: 0;
  border-radius: 100px;
  color: #fff;
  font-size: 17px;
  line-height: 20px;
  font-weight: 700;
}

.controls .model-close {
  text-align: right;
  color: #1b4a6b;
  font-size: 18px;
  cursor: pointer;
}

.controls .model-close span {
  cursor: pointer;
}

.controls h2 {
  margin: 0;
  color: #1b4a6b;
  font-size: 18px;
  line-height: 16.8px;
}

.address-card {
  border: 1px solid #ccc;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 15px;
  margin: 15px 10px 15px 10px;
}

.address-card .address-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-bottom: 1px solid #ccc;
  padding: 5px;
  padding-bottom: 8px;
}

.address-card .address-info label {
  color: #1b4a6b;
  font-size: 14px;
}

.address-card .address-info :first-child {
  font-weight: 700;
}

.address-card .address-info:last-child {
  border: 0;
}

.address-card .address-info label:last-child {
  text-align: right;
}

.address-card .address-info:last-child {
  grid-template-columns: 2fr 1fr;
}

.live-map-carosuel {
  overflow: hidden;
}

.live-map-carosuel .card {
  width: 320px;
  border: 1px solid #ccc;
}

.sliding-divs-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.sliding-divs-container .header {
  height: 44px;
  background-color: #000;
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  padding-right: 10px;
  justify-content: space-between;
  align-items: center;
}

.sliding-divs-container .header button {
  background: transparent;
  border: 0;
  color: #fff;
  cursor: pointer;
}

.sliding-divs-container .header label {
  color: #fff;
}

/* Define the sliding divs */
.sliding-divs {
  display: flex;
  transition: transform 0.3s ease-in-out;
  padding-left: 10px;
  gap: 10px;
}

/* Define the sliding div */
.sliding-div {
  flex-shrink: 0;
  background-color: #fff;
  width: 100%;
}

.job-content {
  margin-right: 10px;
  border-top: 0;
  border-top: 4px solid #ff9649;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.job-content-Assigned {
  border-top: 6px solid #ff9649;
}

.job-content-Completed {
  border-top: 6px solid #00ebb7;
}

.job-content-Exception {
  border-top: 6px solid #ff6363;
}

.job-content-Default {
  border-top: 6px solid #004b6d;
}

.job-info-content {
  border: 1px solid #ccc;
  border-top: 0;
  padding: 8px;
  border-radius: 4px;
  border-top: 0;
}

.job-info-content .table-row {
  border-bottom: 1px solid #ccc;
  display: table-row;
  min-height: 30px;
  color: #000;
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-size: 12px;
  padding: 12px 0;
  align-items: center;
}

.bb-zero {
  border-bottom: 0 !important;
}

.job-info-content .table-row .label {
  color: #1b4a6b;
  font-weight: 700;
  font-size: 12px;
  line-height: 14.4px;
}

.job-info-content .table-row .valueText {
  color: #1b4a6b;
  font-weight: 400;
  font-size: 12px;
  line-height: 14.4px;
  text-align: right;
  justify-content: flex-end;
}

.job-info-content .table-row .valueText label {
  color: #5a5a5a;
}

.job-info-content .table-row:last-child {
  border-bottom: 0;
}

.job-info-content .table-cell {
  display: table-cell;
  padding: 10px;
}

.content-center {
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 5px;
}

.template-1fr-2fr {
  grid-template-columns: 1fr 2fr !important;
}

.flex-center-gap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
}

.wo-number {
  font-weight: 700 !important;
  font-size: 14px !important;
  color: #1b97e0 !important;
  text-decoration-line: underline;
  cursor: pointer;
}

.job-info-footer {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: #004b6d;
}

.job-info-footer button {
  border: 1px solid #004b6d;
  color: #004b6d;
  border-radius: 5px;
  font-weight: 400;
  font-size: 14px;
  background: transparent;
  height: 33px;
  cursor: pointer;
  width: 100%;
  margin-bottom: 10px;
}

.job-info-footer p {
  margin-bottom: 10px !important;
  font-style: italic;
}

.live-job-status-button {
  top: 9%;
  position: fixed;
  left: 20px;
  bottom: 0;
  width: 226px;
  right: 0;
  background: #fff;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  border-radius: 15px;
  height: 50px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-width: 0;
  padding-right: 20px;
  padding-left: 20px;
  cursor: pointer;
  color: #1B4A6B;
  font-weight: 700;
  font-size: 14px;
}

.live-job-status-modal {
  margin-top: 60px;
}

.live-job-status-modal .live-job-status-header {
  display: flex;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;
  align-items: center;
  padding-top: 20px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 20px;
}

.live-job-status-modal .live-job-status-header h3 {
  margin: 0;
  color: #1b4a6b;
  font-weight: 700;
  font-size: 21px;
}

.live-job-job-summary {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  padding: 15px;
}

.live-job-status-modal svg {
  cursor: pointer;
}

.live-job-summary-card {
  background: #f4f4f4;
  height: 90px;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.live-job-summary-card label {
  color: #1b4a6b;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
}

.live-job-summary-card label span {
  display: block;
  font-size: 11px;
  text-align: center;
}

.live-job-card-values {
  display: flex;
  gap: 10px;
  font-weight: 400;
  font-size: 23px;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.live-job-table-summary table {
  width: 100%;
  border-collapse: collapse;
  height: 100%;
}

.live-job-table-summary table thead {
  height: 56px;
  background-color: #f4f4f4;
  border-top: 1px solid #d9d9d9;
}

.live-job-table-summary table thead tr th:first-child,
.live-job-table-summary table tbody tr td:first-child {
  padding-left: 10px;
}

.live-job-table-summary table tbody tr td {
  font-size: 14px;
}

.live-job-table-summary table thead th {
  font-size: 11px;
  line-height: 14px;
  font-weight: 700;
  color: #1b4a6b;
  text-align: center;
  vertical-align: top;
  padding-top: 10px;
}

.live-job-table-summary table thead th:first-child {
  text-align: left;
}

.live-job-table-summary table thead th span {
  font-size: 10px;
  font-weight: 400;
}

.live-job-table-summary table tbody td {
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
  color: #0d2b3a;
  text-align: center;
}

.live-job-table-summary table tbody td:first-child {
  text-align: left;
}


.live-job-table-summary table tbody tr td:first-child {
  color: #1b4a6b;
}

.live-job-table-summary table tbody tr {
  border-bottom: 1px solid #d9d9d9;
  height: 60px;
}

.table-controls {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.table-controls span {
  font-size: 14px;
  font-weight: 700;
}
