.pulse_holder {
  position: absolute;
  left: -33%;
  top: -30%;
}

.pulse_holder img {
  position: absolute;
  left: 0;
}

.pulse {
  position: absolute;
  height: 100px;
  width: 100px;
  left: 50%;
  top: 50%;
  opacity: 0;
  border: 4px solid #0096ff;
  border-radius: 100%;
  animation: pulsate 2.5s ease-out;
  animation-iteration-count: infinite;
  -webkit-animation: pulsate 2.5s ease-out;
  -webkit-animation-iteration-count: infinite;
}

.pulse-1 {
  animation-delay: 0.2s;
}
.pulse-2 {
  animation-delay: 0.4s;
}
.pulse-1 {
  animation-delay: 0.6s;
}
.pulse-1 {
  animation-delay: 0.8s;
}
.pulse-1 {
  animation-delay: 1s;
}

@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
    border-width: 10px;
  }
  10% {
    opacity: 0.8;
  }
  25% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    transform: scale(1.3, 1.3);
    opacity: 0;
    border-width: 1px;
  }
}

@-webkit-keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
    border-width: 10px;
  }
  10% {
    opacity: 0.8;
  }
  25% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    transform: scale(1.3, 1.3);
    opacity: 0;
    border-width: 1px;
  }
}
