/*******************************
         Site Overrides
*******************************/

/*
* Helpers
*/

.clearfix:after {
  content: " ";
  visibility: hidden;
  display: block;
  height: 0;
  clear: both;
}

.foot-contact {
  padding: 40px 0;
  font-weight: bold;
}

/*
* Explicitly set height on react containing divs for login flow
*/

#root,
[data-reactroot] {
  height: 100% !important;
}

.comp,
.content {
  height: 100%;
}

.header-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
}

.ui.container.fluid {
  max-width: 1600px !important;
}

/*
* Logged out background
*/

@media (max-width: @tabletBreakpoint) {
  body.logged-out {
    background: @primaryColor !important;
  }

  .computer-only {
    display: none !important;
  }
}

body.logged-out {
  background: @secondaryColor;

  .content {
    position: static;
  }
}

body.logged-in {
  background: #ffffff;
  padding-bottom: 74px;

  // offset fixed main menu
  .comp {
    top: 74px;

    // offset secondary menu if it exists
    &.secondary-comp > .container,
    &.secondary-comp div > .container {
      padding-top: 64px;
    }
  }

  .compWithMap {
    position: fixed;
    top: 74px;
  }
}

/*
* React Router transitions
*/

.fadeInOut-enter {
  opacity: 0;
}

.fadeInOut-enter-active {
  opacity: 1;
  transition: opacity 250ms;
}

.fadeInOut-leave {
  opacity: 1;
}

.fadeInOut-leave-active {
  opacity: 0;
  transition: opacity 250ms;
}

.content {
  position: relative;
}

.comp {
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
}

/*
* Transitions
*/

.ui.menu {
  .transition {
    transition: transform 2000ms;

    &.scale {
      transform: scale(0, 0);
      transform-origin: center top;
    }

    &.visible {
      transform: scale(1, 1);
    }
  }
}

/*
* Links as Buttons
*/

a.ui.button {
  text-decoration: none !important;
}

/*
* Page Not Found
*/

.ui.grid.not-found {
  margin: 25px 0 0 0;

  .ui.header {
    font-size: 30px;
  }

  p {
    margin-bottom: 40px;
    font-size: 16px;
  }
}

@media (min-width: @tabletBreakpoint) {
  .ui.grid.not-found {
    height: 100%;
    margin: 0;

    .ui.header {
      font-size: 50px;
    }

    p {
      font-size: 22px;
    }
  }

  .mobile-only {
    display: none !important;
  }

  .foot-contact {
    text-align: right;
  }
}

body.logged-out .not-found {
  color: #ffffff;

  .ui.header {
    color: #ffffff;
  }
}

/*
* Mobile Header
*/

.ui.menu .header.item {
  &.muni {
    padding: 0 0 0 20px;

    span:hover {
      cursor: pointer;
    }

    a {
      font-size: 16px;
      color: #ffffff;
      padding: 8px 20px 8px 0;
    }
  }

  &.logo {
    padding-left: 20px;

    &:hover {
      cursor: pointer;
    }

    img {
      width: 80px;
    }
  }
}

/*
* Tablet Header
*/

@media (min-width: @computerBreakpoint) {
  .ui.menu .header.item {
    &.muni a {
      font-size: 22px;
      padding-right: 30px;
    }

    &.logo {
      padding-left: 30px;

      img {
        width: auto;
      }
    }
  }
}

/*
* Global Notifications
*/

.toastify {
  z-index: 12;

  .ui.image.msg-icon {
    width: 28px;
    position: absolute;
    top: 50%;
    margin-top: -14px;
  }

  .inner-content {
    padding-left: 50px;
    display: inline-block;

    span {
      display: block;

      &:first-child {
        font-size: 16px;
        font-weight: bold;
      }
    }
  }

  &.toastify--top-right {
    .toastify-content {
      padding: 25px 20px;
    }

    .toastify__close {
      top: 5px;
      right: 5px;
      padding: 5px;
      position: absolute;

      i.icon {
        margin: 0;
      }
    }
  }
}

.toastify-content {
  border-left: none;
  min-height: 0;
  color: #ffffff;

  .toastify__body {
    font-size: 14px;
  }

  &.toastify-content--error {
    background: #f96a79;
  }

  .toastify__progress--error {
    background: darken(#f96a79, 30%);
  }

  &.toastify-content--success {
    background: #6baf14;
  }

  .toastify__progress--success {
    background: darken(#6baf14, 30%);
  }

  &.toastify-content--info {
    background: #0095e3;
  }

  .toastify__progress--info {
    background: darken(#0095e3, 30%);
  }

  .toastify__close {
    color: #ffffff;
    opacity: 1;
    width: 30px;
    height: 30px;
    padding: 16px;
  }
}

/*
* Map Assets
*/

.live_job_alert_bubble,
.live_job_multiple_alert_bubble {
  cursor: pointer;
  .H_ib_body {
    background: transparent;
    right: 40px;
    bottom: 50px;
    padding-right: 0px;
  }
}

.live_job_alert_bubble .H_ib_close,
.live_job_multiple_alert_bubble .H_ib_close {
  display: none;
}

.live_job_multiple_alert_bubble {
  width: 352px;
  background: #fff;
  min-height: 300px;
  border-top: 4px soldi #004b6d;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  .live_job_multiple_header {
  }
}

.live_job_alert_bubble.H_ib {
  background: none !important;
}

.fade-in-marker {
  opacity: 0;
  transition: opacity 0.5s;
}

.live_job_alert_bubble {

  .table-row {
    border-bottom: 1px solid #ccc;
    display: table-row;
    min-height: 30px;
    color: #000;
    display: grid;
    grid-template-columns: 1fr 1fr;
    font-size: 12px;
    padding: 10px 0;
    align-items: center;

    .label {
      color: #1b4a6b;
      font-weight: 700;
      font-size: 12px;
      line-height: 14.4px;
    }

    .valueText {
      color: #1b4a6b;
      font-weight: 400;
      font-size: 12px;
      line-height: 14.4px;

      strong {
        font-weight: 700;
        margin-bottom: 4px;
      }
    }
  }

  .table-row:last-child {
    border-bottom: 0;
  }

  .table-cell {
    display: table-cell;
    padding: 10px;
  }
}

.live-job-alert-bubble {
  min-height: 181px;
  padding: 10px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 216px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  background: #fff;
  position: relative;
  top: 30px;
  left: -15px;
}

.H_ui .H_ib_body {
  box-shadow: none;
}

.live-job-alert-bubble-move-right {
  left: 295px;
}

.live-job-alert-bubble-move-bottom {
  top: 250px;
}

.live-job-status-Assigned {
  border-top: 4px solid #ff9649;
}

.live-job-status-Completed {
  border-top: 4px solid #00ebb7;
}

.live-job-status-Exception {
  border-top: 4px solid #ff6363;
}

.live-job-status-Multiple {
  border-top: 4px solid #1b97e0;
  min-height: 96px;
  position: absolute;
  left: -170px;
  top: -70px;

  .note {
    color: #004b6d;
    font-size: 12px;
    margin: 0;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
  }
}

.live-multiple-job-alert-bubble-move-right {
  left: 130px;
}

.live-multiple-job-alert-bubble-move-bottom {
  top: 50px;
}

@media (max-width: @tabletBreakpoint) {
  .map-container {
    // markers
    .H_ib.alert_bubble {
      left: -50% !important;
    }

    // info popups
    .H_ib_body {
      margin-right: -150px !important;
    }
  }
}

.map-container .H_ui {
  z-index: 3 !important;
}

.map-container {
  // markers
  .H_ib.alert_bubble {
    position: absolute;
    top: 100px;
    left: -50px;
    transform: none !important;
    animation-duration: 0.75s;
    animation-fill-mode: both;
    animation-name: toastify-bounceInRight;
  }

  .img-marker,
  .circle-marker {
    filter: drop-shadow(0px 3px 8px rgba(0, 0, 0, 0.5));
    &.active-marker {
      z-index: 0 !important;
    }
  }

  .img-marker {
    user-select: none;

    &:first-child {
      z-index: 1 !important;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .circle-marker {
    cursor: zoom-in;
  }

  .circle-marker {
    width: 29px;
    height: 29px;
    position: absolute;
    &.active-marker {
      z-index: 99 !important;
      .pulse_holder {
        top: calc(-120%) !important;
        left: calc(-120%) !important;
      }
    }

    .circle-content {
      position: absolute;
      color: #494949;
      font-size: 16px;
      font-weight: bold;
      top: 3px;
      left: 3px;
      text-align: center;
      width: 23px;
      height: 23px;
      line-height: 22px;
      border-radius: 100%;
      background-color: #ffffff;
      z-index: 50;
    }

    span {
      font-size: 17px;
    }
  }
  .circle-marker, .circle-border, .color {
    content: "";
    position: absolute;
    border-radius: 50%;
    left: 0px;
    top: 0px;
    width: 29px;
    height: 29px;
    margin: 0;
  }

  .color,
  .color.afterMid:after {
    clip: rect(0, 36px, 36px, 18px);
  }

  .color:after,
  .color.afterMid {
    content: "";
    position: absolute;
    border-radius: 50%;
    left: 0px;
    top: 0px;
    width: 36px;
    height: 36px;
    clip: rect(0, 18px, 36px, 0);
  }

  // fixed height and custom vertical scroll for cluster icons
  .H_ib_content {
    overflow-y: visible;
    max-height: 350px;

    &::-webkit-scrollbar {
      width: 6px;
      background-color: #f7f7f7;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #0095e3;
      border-radius: 0;
    }
  }

  .circle-marker:hover {
    cursor: zoom-in;
  }

  .marker-container {
    padding: 16px;
    border-bottom: 2px solid #0095e3;

    p {
      color: #0095e3;
      font-weight: bold;
      text-transform: capitalize;
    }

    .truckId {
      display: inline-flex;
      margin: 5px 0;
      cursor: pointer;

      span {
        margin: 0 0 0 5px;
        text-decoration: underline;
      }
    }

    // trucks
    &.general {
      border-bottom: 2px solid #e53737;

      span.waste-stream {
        color: #e53737;
      }
    }
    &.hardwaste {
      border-bottom: 2px solid #ff5c00;

      p.waste-stream {
        color: #ff5c00;
      }
    }
    &.refuse {
      border-bottom: 2px solid #dc0028;
      p.waste-stream {
        color: #dc0028;
      }
    }

    &.glass {
      border-bottom: 2px solid #9541fe;
      p.waste-stream {
        color: #9541fe;
      }
    }

    &.greenwaste {
      border-bottom: 2px solid #81c734;

      p.waste-stream {
        color: #81c734;
      }
    }

    &.other {
      border-bottom: 2px solid #0095e3;

      p.waste-stream {
        color: #0095e3;
      }
    }

    &.recycle {
      border-bottom: 2px solid #f8e71c;

      p.waste-stream {
        color: #f8e71c;
      }
    }

    // alerts
    &.contaminated {
      border-bottom: 2px solid #b0d500;

      span.reason {
        color: #b0d500;
      }
    }

    &.not_presented {
      border-bottom: 2px solid #f5292a;

      span.reason {
        color: #f5292a;
      }
    }

    &.bin_damaged {
      border-bottom: 2px solid #000000;

      span.reason {
        color: #000000;
      }
    }

    &.bin_not_emptied {
      border-bottom: 2px solid #8d43ff;

      span.reason {
        color: #8d43ff;
      }
    }

    &.other {
      border-bottom: 2px solid #0095e3;

      span.reason {
        color: #0095e3;
      }
    }
  }

  .truck-marker {
    & > img {
      width: 18px;
      display: inline-block;
      float: left;
      margin: 5px 25px 0 5px;
    }

    .truck-info {
      display: inline-block;
      float: left;
      width: auto;
    }
  }

  .activity_area_pop_up {
    z-index: 1;
    cursor: pointer;
    &.active {
      z-index: 999999999;
    }

    &.background {
      opacity: 0.2;
    }
  }

  .activity_area_pop_up .H_ib_body {
    background: rgba(0, 75, 109, 0.9);
    color: #fff;
    padding: 0.7em 1em;
    width: 235px;
    border-radius: 15px;
    border-bottom-left-radius: 0;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
    font-size: 13px;
    margin-right: -245px;

    .H_ib_content {
      .waste_stream,
      .lift_count {
        display: inline-block;
        width: 50%;
      }

      .waste_stream {
        float: left;
        line-height: 1.7;
        font-size: 110%;
        a {
          color: #60d9ff;
        }
      }

      .icon {
        width: 24px;
        height: 24px;
        display: inline-block;
        margin: 0 7px 0 0;
        float: left;
        border-radius: 100%;
        background: #535353;

        &.refuse,
        &.general {
          background: #dc0028;
        }

        &.recycle {
          background: #e8aa0c;
        }

        &.hardwaste {
          background: #ff5c00;
        }

        &.greenwaste,
        &.green {
          background: #42c332;
        }

        &.glass {
          background: #9541fe;
        }

        &.organics {
          background: #42c332;
        }

        &.street_litter {
          background: #000080;
        } 

        img {
          position: absolute;
          left: 11px;
        }
      }

      .close {
        width: 15px;
        height: 15px;
        cursor: pointer;
        position: absolute;
        top: 5px;
        right: 7px;

        &::after {
          content: "\E809";
          font-family: "fontello";
          color: #fff;
          font-size: 75%;
        }
      }

      .set_wastestream {
        display: block;
        width: 100%;
        float: left;
        text-align: left;
        cursor: pointer;
        margin: 3px 0 0 0;

        a {
          color: #60d9ff;
          text-decoration: underline;
        }
      }
    }

    .H_ib_close {
      margin-top: -5px;
      font-size: 90%;
      display: none;
    }
  }

  // info popups - alerts
  .alert_bubble .H_ib_body {
    background: #ffffff;
    border-radius: 0;
    padding: 0;
    color: #434343;
    width: 100%;
    min-width: 300px;
    font-size: 13px;
    bottom: inherit;
    right: 0 !important;
    margin-right: 0;

    span {
      display: block;
      width: 100%;
      margin-bottom: 2px;

      &:last-child {
        margin-bottom: 0;
      }

      &.waste-stream {
        font-size: 13px;
        font-weight: bold;
        color: #0095e3;

        &.general {
          color: #e53737;
        }

        &.greenwaste {
          color: #81c734;
        }

        &.recycle {
          color: #f8e71c;
        }
      }

      &.reason {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 5px;

        img {
          width: 22px;
          vertical-align: bottom;
        }
      }

      &.truck-id {
        font-size: 16px;
        font-weight: bold;
      }

      &.time {
        color: #9b9b9b;
        font-size: 13px;
      }

      &.rego,
      &.job {
        color: #909090;
      }

      &.content-container {
        width: auto;
        display: flex;
        & > span {
          display: inline-block;
          float: left;
          width: auto;
        }
      }

      &.img-container {
        position: relative;
        display: inline-block;
        float: left;
        margin-right: 15px;

        img {
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.03);
          border: 3px solid #ffffff;

          &:hover {
            cursor: pointer;
          }
        }

        &:after {
          content: "\E806";
          font-family: "fontello";
          position: absolute;
          bottom: 16px;
          right: 8px;
          color: #ffffff;
        }
      }

      &.address {
        color: #0095e3;
        border-bottom: 1px solid #f7f7f7;
        font-weight: bold;
        padding-bottom: 15px;
        margin-bottom: 15px;

        .icon {
          font-size: 1.3em;
        }
      }

      &.address_house_section {
        & > span,
        .icon {
          color: #67cb48;
        }
      }

      &.updated {
        color: #909090;
        font-style: italic;
        font-size: 11px;
      }
    }
  }

  // info popups - hints
  .hint_bubble {
    .H_ib_body {
      background-color: rgba(0, 75, 109, 0.9);
      padding: 0.8em 1.7em 0.8em 0.8em;
      font-family: Lato;
      font-size: 13px;
      border-radius: 12px 12px 12px 0px;
      margin-right: unset;
      right: unset;
      margin-left: 1em;
      animation: openHintBubble 500ms;
      max-height: 500px;
      overflow-y: visible;
      color: #ffffff;
      p {
        white-space: nowrap;
        margin: 0;
        color: #ffffff;
      }

      a {
        cursor: pointer;
        color: #60d9ff !important;
        text-decoration: underline;
      }

      .title {
        font-weight: bold;
        margin-bottom: 0.5em;
      }
    }
    .H_ib_content {
      padding-right: 0.5em;
    }

    .H_ib_close {
      top: 0.5em;
      right: 0.8em;
      font-size: 0.75em;
    }

    @keyframes openHintBubble {
      0% {
        transform: scale(0);
        transform-origin: bottom left;
      }
      25% {
        transform: scale(0);
      }
      100% {
        transform: scale(1);
        transform-origin: bottom left;
      }
    }
  }

  // close icon
  .H_ib_close {
    font-size: 1em;
    right: 1em;
    top: 1em;

    svg {
      display: none;
    }

    &:after {
      content: "\e809";
      font-family: "fontello";
    }
  }

  .H_icon {
    fill: #434343;
  }

  .H_btn .H_icon {
    fill: #ffffff;
  }
  .H_btn:hover > svg.H_icon {
    fill: #ffffff;
  }

  .H_l_right {
    right: 100%;
  }

  .H_copyright {
    right: unset !important;
    left: 0px;
  }

  .H_l_bottom {
    bottom: 0px;
  }

  .H_zoom .H_btn, [title='Choose view'] {
    width: 3em;
    background: #1f262a
  }

  .H_overlay.H_el.H_open .H_btn {
    background: #ffffff
  }

  .circle-marker:hover, circle-content:hover {
    cursor: pointer !important;
  }

  .H_ctl.H_grp.H_zoom {
    background: #1f262a
  }

  .H_ctl {
    margin: .6em;
  }

  .H_scalebar { 
    margin-top: 36px;
  }

  .H_rdo_buttons.H_el .H_btn {
    background: #ffffff
  }
  
  .H_ib_content {
    margin: 0;
    padding: 0;
  }

  .H_ib_tail {
    display: none;
  }
}

/*
* Reports
*/

.reports {
  .actions {
    margin-bottom: 30px;
    padding-top: 30px;
    border-top: 1px solid rgba(151, 151, 151, 0.1);

    a {
      width: 100%;
    }
  }
}

/*
* Report Subscriptions
*/

.manage-subscriptions {
  padding-top: 64px;

  &.ui {
    text-decoration: underline;
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    color: #616161;
    padding-left: 5px;
    &:hover {
      text-decoration: underline;
    }
  }
  .ui.text.container:first-child {
    margin: 30px 0;
  }

  h1.ui.header .sub.header {
    font-size: 30px;
    font-weight: 600;
    color: #004a6e;
    margin-top: 5px;
  }
  .ui.grid.actions {
    margin-top: 30px;
    .link.cancel {
      text-decoration: underline;
      display: block;
      text-align: center;
      font-family: Lato;
      font-size: 16px;
      color: #616161;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .add-subscription {
    background: #f7f7f7;
    padding: 25px 0;

    .ui.text.container {
      margin-top: 30px !important;
      margin-bottom: 30px !important;
    }

    h4 {
      color: #004b6d;
      font-size: 16px;
    }
  }
}

/*
* Custom Reports
*/
.custom-reports {
  padding-top: 65px;
  h4 {
    height: 16px;
    font-family: Lato;
    font-size: 14px;
    text-align: left;
    color: #004b6d;
    margin-bottom: 20px;
    font-weight: 520;
  }

  h5 {
    height: 16px;
    font-family: Lato;
    font-size: 14px;
    text-align: left;
    color: #004b6d;
    margin-bottom: 20px;
    font-weight: 600;
  }

  .custom-reports-container__desktop {
    display: none !important;
  }
  &-form-container {
    margin-top: 20px !important;
    .module {
      padding-left: 10px;
      margin-bottom: 20px !important;

      .input-wrap {
        margin-bottom: 10px;
        .ui.checkbox {
          label {
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            font-size: 14px;
            color: #444444;
            &::before {
              opacity: 0.35;
              background-color: #d8d8d8;
              border: solid 1px #979797;
              width: 14px;
              height: 14px;
              border-radius: 2px;
              top: 3px;
            }
            &::after {
              font-size: 8px;
              height: 14px;
              width: 14px;
              top: 5px;
            }
          }
          &.checked {
            label {
              color: #0095e3;
            }
            .box::before,
            label::before {
              opacity: 1;
              background-color: #0095e3;
              border: solid 1px #004b6d;
            }
          }
        }
      }
    }
  }
  // reset link
  .reset-fields {
    display: block;
    font-size: 14px;
    text-align: right;

    span {
      text-decoration: underline;
      margin-left: 10px;

      &:hover {
        text-decoration: none;
        cursor: pointer;
      }
    }
  } // reset fields

  .header-container {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .actions {
    .link.cancel {
      text-decoration: underline;
      display: block;
      text-align: center;
      font-family: Lato;
      font-size: 16px;
      color: #616161;
      &:hover {
        text-decoration: underline;
      }
    }
    .actions-button-wrapper {
      padding: 0 0 5px 0 !important;
    }
  }

  .container.actions {
    padding-top: 25px !important;
    padding-bottom: 30px;
    margin-top: 25px;
    border-top: solid 1px #f2f2f2;

    .button:first-child {
      margin-bottom: 20px;
    }
  }

  .reset-inter {
    margin-top: 1em;
  }

  .filter-super-wrap {
    padding: 0.8em 0 0 1.6em;
  }

  .filter-wrap {
    display: list-item;
    padding-left: 0.8em;
    .field.filter {
      margin-top: 10px;

      &.filter__input {
        input {
          height: 36px;
          border-radius: 5px;
          background-color: #ffffff;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.03);
          border: @solidFieldBorderColour;
          padding: 0.25em 2.25em 0.25em 0.5em !important;
        }
      }

      .ui.selection.dropdown {
        padding: 0.125em 1.5em 0.125em 0.5em;
        background-color: #fff;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.03);
        border: @solidFieldBorderColour;
        font-size: 14px;
        .menu {
          max-height: 200px !important;
          border: @solidFieldBorderColour;
          &:hover {
            border: @solidFieldBorderColour;
          }
          & > .item {
            font-size: 14px;
            padding: 0.75em 2.2em !important;
          }
        }
        &.active i.icon.delete::before {
          content: "\F00D";
        }
        i.icon.angle {
          position: absolute !important;
          top: 10px;
          right: 3px;
          z-index: 10px;
        }
        i.icon.delete {
          font-size: 14px;
        }
        .text {
          padding: 0;
          line-height: 0.65em;
          margin: 0.8em 0 0.8em 0.4em;
        }
      }
      .ui.multiple.dropdown {
        min-height: 1em;
        .label {
          font-size: 0.75em;
          background-color: #ffffff;
          margin-right: 10px;
        }
        & > input.search {
          min-width: 6em;
          margin: 0;
          padding: 0.125em 0 0.25em 0;
          border-bottom: @solidFieldBorderColour;
        }
      }
    }
  }

  .ui.icon.input {
    i.icon {
      height: 115%;
      width: 3em;
    }
  }
}

.custom-report-download {
  .field {
    margin-bottom: 1em;
  }

  .field .ui.selection.dropdown {
    width: 100%;
  }
}

.datepicker {
  .DateRangePicker {
    display: block;
  }
  .DateRangePickerInput {
    cursor: pointer;
    display: block;
    word-wrap: break-word;
    line-height: 1em;
    white-space: normal;
    outline: 0;
    width: 100%;
    min-width: 14em;
    min-height: 4em;
    background: rgba(239, 239, 239, 0.41);
    padding: 1.42857143em 3.38571429rem 1.42857143em 2.28571429rem;
    color: rgba(0, 0, 0, 0.87);
    border: 3px solid rgba(34, 36, 38, 0.15);
    border-radius: 6px;
  }
  .DateInput_input {
    background: rgba(239, 239, 239, 0.41);
    padding: 0;
    margin: 0;
    text-align: center;
  }

  .DateInput_1 {
    width: 45%;
  }
}

/*
Overrides for Semantic UI Grid
*/
@media only screen and (max-width: 767px) {
  .custom-reports {
    .actions {
      .ui.stackable.grid > .column:not(.row) {
        padding: 0 0 5px 0 !important;
      }
      .ui.stackable.grid > .column:first-child {
        padding-top: 1rem !important;
      }
      .ui.stackable.grid > .column:last-child {
        padding-bottom: 1rem !important;
      }
    }
  }
}

/*
* MOBILE - TABLET (re-enable with 'save report' action)
*/
@media (min-width: 480px) and (max-width: @tabletBreakpoint) {
  .custom-reports {
    .column-menu-container {
      padding: 0 !important;
    }
  }
}

/*
* TABLET
*/

@media (min-width: @tabletBreakpoint) {
  /*
  * Report Subscriptions
  */
  .manage-subscriptions {
    .ui.text.container:first-child {
      margin: 50px 0;
    }
  }
} // tablet

/*
* COMPUTER
*/
@media (min-width: @computerBreakpoint) {
  /*
  * Reports
  */
  .custom-reports {
    h4,
    h5 {
      padding: 20px 0;
    }

    h5 {
      font-weight: 500;
      padding: 20px 0 20px 10px;
    }

    &-form-container {
      margin-top: 0 !important;
      .filter-wrap .field.filter .ui.selection.dropdown {
        width: 90%;
        min-width: 11em;
      }
    }
    .sidebar-menu {
      .menu-item {
        padding: 20px 0 20px 15px !important;
        border-bottom: solid 0.5px #e8e8e8;
        font-family: Lato;
        font-size: 14px;
        font-weight: bold;
        text-align: left;
        color: #004b6d;
        span {
          display: inline-block;
          min-width: 85%;
        }
        .icon {
          display: none;
        }
        &.active:not(.disabled) {
          color: #0095e3;
          .icon {
            display: inline-block;
          }
          &:hover {
            text-decoration: none;
            background-color: white;
            cursor: auto;
          }
        }
        &:hover:not(.disabled) {
          cursor: pointer;
          background-color: darken(#fff, 5%);
        }
        &.disabled {
          opacity: 0.3;
        }
      }
    }

    .actions {
      .link.cancel {
        display: block;
        text-align: center;
      }
    }
    .container.actions {
      .button {
        width: 48%;
        display: inline-block;
        margin-bottom: 0;

        &:first-child {
          margin-right: 4%;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .action-bar {
      display: inline-block !important;
      margin-top: 30px !important;

      .report-date {
        margin-right: 20px;
        line-height: 66px;

        .ui.inline.dropdown > .text {
          line-height: 1.5;
        }
      }

      .inner-actions {
        float: right;
        padding-top: 1.5em;
      }

      .ui.massive.button {
        padding-left: 40px !important;
        padding-right: 40px !important;

        &:first-child {
          margin-right: 10px !important;
        }
        &:last-child {
          margin-right: 0 !important;
        }
      }
    }

    .custom-reports-container__desktop {
      display: block !important;
    }

    .module {
      padding: 40px 10px 10px 24px !important;
    }
    .actions {
      padding-right: 10px !important;
      text-align: right;
      border-top: none;
      a,
      .link.cancel {
        display: inline-block;
        width: 10%;
      }
      .ui.fluid.button {
        display: inline-block;
        width: 175px;
        padding: 20px 25px;
      }
      .actions-button-wrapper {
        padding: 0 0 5px 0 !important;
      }
    }

    .report-config-container {
      display: flex;
      border: solid 1px #e8e8e8;

      aside.sidebar-menu {
        display: flex;
        flex-direction: column;
        width: 247px;
      }
      .report-config-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        border-left: solid 1px #e8e8e8;
      }
      .cleared {
        clear: both;
      }
    }
  }

  .report-config {
    padding: 40px 0;

    // accordion
    .ui.accordion {
      // title
      .title:not(.ui) {
        display: inline-block;
        line-height: 1;

        .title-text {
          font-size: 26px;
          margin-right: 20px;

          i.icon {
            font-size: 28px;
          }
        }
      }

      .reset-fields {
        display: inline-block;
        float: right;
        margin: 0;
        padding: 5px 0;
      }

      .content {
        border-top: none;
        margin-top: 0;

        .module {
          border-right: 1px solid #e3e3e3;
          padding-left: 40px;

          &:first-child {
            padding-left: 1em !important;
          }

          h4 {
            margin-bottom: 30px;
          }

          &:last-child {
            border-right: none;
          }
        }
      }
    } // accordion
  } // report-config
} // computer

/*
* LARGE MONITOR
*/

@media (min-width: @largeMonitorBreakpoint) {
  .custom-reports {
    .header-container {
      padding-top: 50px !important;
      padding-bottom: 50px !important;
    }

    .action-bar {
      margin-top: 0 !important;
      text-align: right;
    }
  }
} // large monitor

a.cancel {
  cursor: pointer;
}

.map-view-switch {
  background-color: #004b6d;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-right: 10px;
  border-radius: 5px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.map-view-switch a {
  background-color: #1b4b6a;
  border: 0;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
}

.map-view-switch a {
  height: 100%;
  border-radius: 5px;
  width: 125px;
  color: #fff;
}

.map-view-switch a.active {
  background-color: #fff;
  color: #000;
}

.live-job-status-model {
  width: 40% !important;
  max-width: 518px;
}

.jobs-work-order-panel {
  h3 {
    font-weight: 800;
    font-size: 24px;
    color: #004b6d;
  }
}

.job-view-container {
  .work-orders-left-column {
    background: #f9f9f9;
    position: fixed !important;
    max-height: 100vh;
    overflow-y: auto;
    padding-bottom: 250px !important;
    left: 0;
  }

  padding-top: 14px !important;

  .left-border-pd-zero {
    border-left: 1px solid #ccc;
    padding: 0 !important;
    padding-right: 20px !important;
    position: absolute !important;
    right: 0;
  }

  .content-left {
    padding: 10px;


    h2 {
      margin: 0;
      color: #004b6d;
      font-size: 24px;
      padding: 0;
      font-weight: 800;
    }

    margin-bottom: 20px;

    ul {
      margin: 0;
      padding: 0;
      margin-top: 20px;

      li.selected {
        border: 3px solid #1b97e0;
      }

      li {
        border: 1px solid #e6e6e6;
        padding: 20px 10px;
        margin-bottom: 10px;
        cursor: pointer;
        background: #fff;
        display: flex;
        flex-direction: row;

        .job-view-container-left {
          display: flex;
          flex-direction: column;
          gap: 5px;
          flex: 1;
          margin-right: 10px;

          label {
            font-size: 16px;
            font-weight: 700;
            color: #0d2b3a;
          }

          span {
            color: #1b97e0;
            font-size: 14px;
            font-weight: 700;
          }
        }

        .job-view-container-right {
          display: flex;
          justify-content: right;
          align-items: center;
          gap: 10px;

          button {
            padding: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
            border-radius: 50px;
            border: 1px solid #d9d9d9;
            width: 120px;
          }
        }
      }
    }
  }

  .full-width {
    width: 100%;
  }

  .three-quarter-width {
    width: 75%;
  }

  .content-right {
    padding: 25px;
    background-color: #f4fafe;
    position: fixed;    
    z-index: 1;

    .header-top {

      h3 {
        font-size: 36px;
        color: #004b6d;
        font-weight: 800;

        span {
          color: #1b97e0;
        }
      }
    }

    .content-right-bottom {
      display: grid;
      grid-gap: 20px;
      gap: 20px;
      grid-template-columns: 4fr 4fr 0fr;

      .content-right-bottom-left {
        display: flex;
        flex-direction: column;
      }

      .content-right-detail {
        h6 {
          font-weight: 700;
          font-size: 18px;
          margin: 0;
          padding: 0;
          color: #1b97e0;
          margin-top: 15px;
          padding: 0;
        }
        p {
          font-size: 14px;
          font-weight: 400;
          max-width: 200px;
          color: #0d2b3a;
        }
      }
    }

    .content-right-bottom-right {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      width: 100%;

      button {
        background-color: #004b6d;
        justify-content: center;
        align-items: center;
        display: flex;
        padding: 10px;
        border-radius: 50px;
        border: 0;
        color: #fff;
        gap: 10px;
        width: 167px;
        height: 45px;
      }
    }
  }

  .content-below {
    padding: 25px;
  }
}

.collapsible-item-body {
  background: #f8f8f8;
  padding: 10px;
}

.date-range-picker-wrapper {
  position: relative;
  display: inline-block;
  margin: 0 1.25em;
  height: 44px;
  background: #004b6d;
  align-items: center;
  display: flex;
  padding-left: 10px;
}

@media only screen and (max-width: @computerBreakpoint) {
  .date-range-picker-wrapper {
    display: none;
  }
}

.date-range-picker-wrapper button {
  background: #004b6d;
  border: 0;
}

.date-range-picker-wrapper button svg {
      fill: #0095e3 !important;
    background: #fff;
    padding: 3px;
    border-radius: 100px;
}

.DateRangePickerInput_clearDates_svg {
  height: 15px;
    width: 15px;
}

.DateRangePickerInput_clearDates_default:focus, .DateRangePickerInput_clearDates_default:hover {
  background: transparent
}

.date-range-picker-wrapper img {
  width: 18px;
}

.date-range-picker-wrapper input {
  background: #004b6d;
  color: #fff;
}

.date-range-picker-wrapper .DateRangePickerInput {
  border: 0;
}

.date-range-picker-wrapper .DateRangePickerInput__withBorder {
  background: #004b6d;
}

.date-range-picker-wrapper .DateRangePickerInput_arrow_svg {
  fill: #fff;
}

.cursor-disabled {
  cursor: not-allowed !important;
}