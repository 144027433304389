
/*******************************
         Site Overrides
*******************************/

.ui.segment.box-form {
  border: none;
  background: #004A6E;
  color: #FFFFFF;

  a {
    color: #FFFFFF;
    text-decoration: underline;

    &:hover, &:focus {
      text-decoration: none;
    }
  }
}

.ui.secondary.segment {
  border: none;
  box-shadow: none;
}

.gray-seg {
  h3 {
    color: #004b6d;
    font-size: 16px;
    font-weight: 'bold',
  }

  p {
    font-size: 16px;
    color: #424852;
    line-height: 1.8;
  }
}
