
/*******************************
         Site Overrides
*******************************/

/*
* Log in forms
*/

@media (min-width: @tabletBreakpoint) {
  .ui.segment.box-form {
    box-shadow: 0 2px 46px 0 rgba(0, 0, 0, 0.15);
  }
}

.ui.segment.box-form {

  .ui.form {

    // normal
    input[type=email],
    input[type=password] {
      background: #003D5B;
      border-color: #003D5B;
      border-width: 3px;
      color: #FFFFFF !important;
      font-style: italic;
      font-size: 16px;
      height: 64px;

      &:focus {
        background: #003D5B;
        border-color: #0095E9;
      }
    }

    // error
    .field.error {
      input[type=email],
      input[type=password] {
        background: #003D5B;
        border-color: #FF2920;

        &:focus {
          background: #003D5B;
        }
      }

      // error msg
      span {
        color: #FFFFFF;
      }
    }
  }
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #A6AEB2 !important;
}
::-moz-placeholder { /* Firefox 19+ */
  color: #A6AEB2 !important;
}
:-ms-input-placeholder { /* IE 10+ */
  color: #A6AEB2 !important;
}
:-moz-placeholder { /* Firefox 18- */
  color: #A6AEB2 !important;
}

.ui.search-input .ui.input input {
  height: 65px;
}

/*
* Auto Complete
*/

.box-form .ui.form .field.field {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    box-shadow: none !important;
    border-color: #003d5b !important;
    background-color: #003d5b !important;
    -webkit-box-shadow: 0 0 0 1000px #003d5b inset !important;
    -webkit-text-fill-color: #FFFFFF !important;
  }
}
