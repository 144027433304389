
/*******************************
         Site Overrides
*******************************/

.ui.massive.button {
  padding: 24px 60px;
  font-size: 18px;
  position: relative;

  & > .icon:not(.button) {
    margin-left: -15px !important;
    margin-right: 15px !important;
    opacity: 1;
  }
}

.ui.basic.blue.button.loading:after {
  border-top-color: #0095E3;
}

.load-more-btn {
  width: 300px !important;
  margin: 0px 0px 10px 0px !important;
  padding: 1.5em !important;
  line-height: .25em !important;
  background-color: white !important;
  // border: solid 2px #0095E3 !important;
  font-size: 1.25em !important;
  color: #0095E3 !important;
  align-self: center !important;

  &.loading {
   color:blue;
  }
}
