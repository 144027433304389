
/*******************************
             Sidebar
*******************************/

// mobile sub-menu accordion
.ui.accordion.menu-accordion {
  min-height: 0;

  // content
  .title~.content {
    padding: 0 !important;
  }

  // toggle button
  .title {
    padding: 0;
    background: #0095E3;
    position: absolute;
    top: 0;
    right: 0;
    height: 65px;
    width: 72px;
    text-align: center;
    display: none; // HIDE MOBILE TOGGLE

    &.active {
      i.icon:before {
        content: "\e803";
      }
    }

    i.icon {
      font-size: 1.5em;
      color: #FFFFFF;
      height: 65px;
      line-height: 65px;
      margin: 0;
    }
  }
}

.ui.secondary.menu .item.search-input {
  padding: 0 !important;
  position: static;
  margin-left: 0;
  margin-right: 0;
  height: 46px;

  // active - user is typing || results shown
  &.active,
  &.shown {
    & > .input {
      input {
        background: #0095e3 !important;
        border: 1px solid transparent;
      }

      .ui.button.label {
        display: block;

        img {
          width: 20px !important;
        }
      }
    }
  }

  &.active > .input input {
        border-radius: 5px 5px 0 0;
  }

  &.shown > .input:not(.loading) {
    i.icon:before {
      font-family: 'fontello';
      content: '\e800';
      font-size: 25px;
    }
  }

  // loading
  .ui.loading.loading.input > i.icon::after {
    border-color: rgb(255, 255, 255) transparent transparent;
  }

  & > .input {
    width: 100%;

    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: #FFFFFF !important;
      font-style: italic;
    }
    ::-moz-placeholder { /* Firefox 19+ */
      color: #FFFFFF !important;
      font-style: italic;
    }
    :-ms-input-placeholder { /* IE 10+ */
      color: #FFFFFF !important;
      font-style: italic;
    }
    :-moz-placeholder { /* Firefox 18- */
      color: #FFFFFF !important;
      font-style: italic;
    }

    i.icon {
      opacity: 1;
      color: #FFFFFF;
      font-size: 20px;
    }

    .ui.button.label {
      background: none;
      height: 50px;
      padding: 0 15px;
      position: absolute;
      right: 5px;
      display: none;
    }

    input {
      color: #FFFFFF;
      font-size: 16px;
      text-overflow: ellipsis;
      height: 45px;
      border: 1px solid transparent;
      border-radius: 5px;
      background-color: #004b6d;

      &:focus,
      &:active {
        background: #0095e3;
        border: 1px solid transparent;
      }
    }
  }
}

// results
.ui.search > .results {
  width: 100%;
  top: 65px;
  height: 100%;

  .result {
    border-bottom: 1px solid rgba(216, 216, 216, 0.2);
    padding: 0;
    &.active,
    &:hover,
    &:active {
      background: #FFFFFF;

      i.icon {
        color: #0095E3;
      }
    }

    .result-container {
      display: flex;
    }

    .inner {
      flex-basis: 90%;
    }

    i.icon {
      color: #B2B2B2;
      font-size: 1.5em;
      vertical-align: sub;
      text-align: left;
      flex-basis: 10%;
      line-height: 50px;
      height: 48px;
    }

  }
}

@media (min-width: @tabletBreakpoint) {
  .ui.accordion.menu-accordion.tabletBreakpoint {
    .title {
      display: none;
    }

    .title~.content {
      display: block;
    }
  }  
}

@media (min-width: @computerBreakpoint) {
  .ui.accordion.menu-accordion.computerBreakpoint {
    .title {
      display: none;
    }

    .title~.content {
      display: block;
    }
  }
}

@media (min-width: 1146px) {

  .ui.accordion.menu-accordion {
    .title {
      display: none;
    }

    .title~.content {
      display: block;
    }
  }

  .ui.secondary.menu .item.search-input {
    padding-right: 0 !important;

    & > .input {
      width: 375px;
      margin-right: 0 !important;
      >i.icon {
        width: 52px;
      }
    }
  }

  .ui[class*="left icon"].input>input {
    padding-left: 56px !important;
  }

  // results
  .ui.search > .results {
    width: 375px;
  }
}

.ui.search.search-input > .results {
  position: fixed;
  top: 139px;
}

@media (min-width: @tabletBreakpoint) {
  .ui.search.search-input > .results {
    position: absolute;
    top: 53px;
    left: 7px;
    width: 375px;
    height: 267px;
    overflow-y: auto;
  }
}
