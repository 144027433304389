
/*******************************
             Table
*******************************/

/*
* BASE STYLES
*/

/*
* STANDARD
*/
.ui.celled.basic.table.standard {

  // hide headers for mobile
  thead { display: none; }

  /*
  * REPORTS
  */
  &.reports {
    td {
      &.download {
        button { width: 100%; }
      }

      &.subscriptions {
        white-space: nowrap;

        span {
          &.none {
            color: #9EA1A6;
          }
        }

        .left-col,
        .right-col {
          display: inline-block !important;
        }

        .left-col {
          width: 60% !important;
        }

        .right-col {
          text-align: right;
          width: 40% !important;
        }

        .ui.button.basic {
          box-shadow: none;
          color: #434343 !important;
          border: solid 1px #D9DADC;
        }
      }
    }
  } // reports

  /*
  * SUBSCRIPTIONS
  */
  &.subscriptions {
    border-spacing: 0 12px;
    margin-top: 50px;

    thead {
      tr th {
        padding: 8px 42px;
      }
    }

    tr {
      td {
        padding: 24px 42px;

        &:last-child {
          border-left: none;
        }

        &.remove {
          span {
            text-decoration: underline;
            font-weight: bold;
            color: #FF3F54;
            font-size: 14px;

            &:hover {
              cursor: pointer;
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  /*
  * TICKETS
  */
  &.tickets {
    tr {
      td {
        &.ticket-id {
          font-weight: bold;
          color: #0095E3 !important;
        }
      }
    }
  }

  /*
  * ALERTS
  */
  &.alerts {
    td {
      &.address .show-on-mobile,
      .responsive-break {
        display: none;
      }

      &.waste-stream {
        padding-top: 24px;
        padding-bottom: 24px;
        white-space: nowrap;

        .truck {
          transform: rotate(135deg) scale(0.5);
          margin-right: 10px;
        }
      }

      &.time {
        white-space: nowrap;
      }

      &.type {
        white-space: nowrap;

        i.icon-warning { margin-right: 15px; }
      }
    }

  } // alerts

} // standard

/*
* SINGLE TICKET
*/
.ticket-view {
  .ui.grid.multi-header > .row {
    border-bottom: none;
    padding-bottom: 0;
  }

  .ui.header {
    span {
      color: #004B6D;
    }
  }

  .time-stamp {
    color: #424852;
  }
} // single ticket

/*
* REPORT PREVIEW
*/

.ui.celled.basic.table.standard.report-preview {

  thead {
    display: table-header-group;

    tr th {
      border-left: 2px solid #F4F4F4;
      white-space: nowrap;
      padding-left: 42px;
      padding-right: 42px;

      i.icon.icon-cancel {
        background: #DBDBDB;
        color: #FFFFFF;
        box-shadow: none;
        font-size: 10px;
        margin-left: 15px;

        &:hover {
          cursor: pointer;
          background: darken(#DBDBDB, 5%);
        }
      }
    } // th
  }

  tr td {
    white-space: nowrap;
  }
}

// custom scollbar for preview
.custom-scroll {
  margin-top: 50px;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    height: 6px;
    background-color: #F7F7F7;
  }

  &::-webkit-scrollbar-thumb {
      background-color: #0095E3;
      border-radius: 0;
  }
}

/*
* UP TO TABLET
*/
@media (max-width: @mobileMaxWidth) {

  /*
  * STANDARD
  */
  .ui.table.basic.standard {
    tbody {
      tr {
        padding: 0;
        border: solid 1px rgba(151, 151, 151, 0.13);
        margin-bottom: 12px;
        box-shadow: 0 3px 10px 0 rgba(204, 204, 204, 0.23) !important;
      }
    }
  }

  /*
  * SUBSCRIPTIONS
  */
  .ui.celled.basic.table.standard.subscriptions {
    tr td { padding: 22px; }
  }

  /*
  * REPORTS
  */
  .ui.table.basic.standard.reports {

    tr {
      background: #FCFCFC;

      td {
        padding: 1.25em 0.75em !important;

        .mobile-heading {
          font-weight: bold;
          font-size: 12px;
          color: #004B6D;
          display: block;
        }

        &.name,
        &.author {
          display: inline-block !important;
        }

        &.name {
          font-weight: normal;
          width: 60% !important;
        }

        &.author {
          width: 40% !important;
        }

        &.subscriptions {
          border-top: 1px solid rgba(151, 151, 151, 0.1) !important;
        }

        &.download {
          padding: 0.75em !important;
          border-top: 1px solid rgba(151, 151, 151, 0.1) !important;
        }
      }
    }

  } // reports

  /*
  * REPORT PREVIEW
  */
  .preview-container {
    padding-top: 35px !important;
    padding-bottom: 10px;

    .report-date {
      display: block;
      margin-bottom: 25px;

      .field { display: inline-block; }

      .ui.inline.dropdown {
        .menu {
          margin-top: 10px;
        }

        i.icon { height: auto; }
      }
    }
  }

  /*
  * TICKETS
  */
  .ui.celled.basic.table.tickets {
    tr {
      td {
        &.created,
        &.status {
          font-size: 12px;
          display: inline-block!important;
          width: 50%!important;
          border-top: 1px solid rgba(151,151,151,.1)!important;
          padding: 17px !important;
        }

        &.status {
          text-align: right;
        }

        &.ticket-id {
          padding: 17px !important;
          font-size: 18px;
        }

        &.address,
        &.reason, {
          padding: 0 17px 17px 17px !important;
        }
      }
    }
  } // tickets

  /*
  * USER MANAGEMENT
  */
  .ui.celled.basic.table.user-management tr {
    background: #FCFCFC !important;
    position: relative !important;

    td {
      padding: 17px !important;

      &.first-name,
      &.last-name {
        font-weight: bold;
        font-size: 16px;
        display: inline-block !important;
        padding-bottom: 5px !important;
      }

      &.first-name {
        padding-right: 0 !important;
      }

      &.last-name {
        padding-left: 4px !important;
      }

      &.email {
        font-size: 16px;
        padding-top: 0 !important;
      }

      &.type, &.status {
        font-size: 12px;
        display: inline-block !important;
        width: 50% !important;
        border-top: 1px solid rgba(151, 151, 151, 0.1) !important;
      }

      &.type {
        color: #797979;
      }

      &.status {
        text-align: right !important;

        img {
          position: absolute;
          top: 35px;
          right: 15px
        }
      }
    }
  }

  /*
  * ACCOUNT MANAGEMENT
  */
  .ui.celled.basic.table.standard.account-management {
    tr td {
      padding: 22px !important;
    }
  }

  /*
  * ALERTS
  */
  .ui.celled.basic.table.standard.alerts {

    tr {
      background: #FCFCFC;

      td {
        padding: 17px !important;

        &.thumb-cell {
          display: inline-block !important;
          width: 40% !important;
          max-width: 100px;
        }

        &.address {
          display: inline-block !important;
          font-size: 12px !important;
          width: 60% !important;
        }

        &.waste-stream {
          position: relative;
          padding-left: 50px !important;

          .truck {
            position: absolute;
            left: 17px;
            top: 0;
          }
        }

        &.time {
          text-align: right;
        }

        &.waste-stream,
        &.time {
          font-size: 12px;
          display: inline-block!important;
          width: 50%!important;
          border-top: 1px solid rgba(151, 151, 151, 0.1)!important;
        }

      } // td

    } // tr

  } // alerts

  /*
  *   USER MANAGEMENT
  */
  .ui.celled.basic.table.user-management tr {
    background: #FCFCFC !important;
    position: relative !important;

    td {
      padding: 17px !important;

      &.first-name,
      &.last-name {
        font-weight: bold;
        font-size: 16px;
        display: inline-block !important;
        padding-bottom: 5px !important;
      }

      &.first-name {
        padding-right: 0 !important;
      }

      &.last-name {
        padding-left: 4px !important;
      }

      &.email {
        font-size: 16px;
        padding-top: 0 !important;
      }

      &.type, &.status {
        font-size: 12px;
        display: inline-block !important;
        width: 50% !important;
        border-top: 1px solid rgba(151, 151, 151, 0.1) !important;
      }

      &.type {
        color: #797979;
      }

      &.status {
        text-align: right !important;

        img {
          position: absolute;
          top: 35px;
          right: 15px
        }
      }
    }
  }

} // up to tablet

.ui.selectable.table.standard tbody tr:hover {
  background: #FCFCFC !important;
  box-shadow: 0 3px 10px 0 rgba(204, 204, 204, 0.23), 0 2px 14px 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;
}

.ui.celled.basic.table.standard {
  border: none;
  margin-bottom: 50px;

  thead tr {
    box-shadow: none;

    th {
      font-size: 14px;
      border-bottom: none;
      border-left: none;
      border-top: none;
      padding-top: 18px;
      padding-bottom: 18px;
    }

    & > th:first-child,
    & > th:last-child {
      border-radius: 0;
    }
  }

  tr {
    box-shadow: 0 3px 10px 0 rgba(204, 204, 204, 0.23);

    td {
      border-top: solid 1px rgba(151, 151, 151, 0.13);
      border-bottom: solid 1px rgba(151, 151, 151, 0.13);
      border-left: solid 1px rgba(151, 151, 151, 0.13);
      padding: 42px;
      color: #424852;
      background: #FCFCFC;

      &:last-child {
        border-right: solid 1px rgba(151, 151, 151, 0.13);
      }

      &.actions-cell {
        padding-top: 0;
        padding-bottom: 0;
      }

      &.thumb-cell {
        padding-top: 24px;
        padding-bottom: 24px;
        padding-left: 24px;

        &:hover {
          cursor: pointer;
        }

        .image.thumb,
        .image.thumb img {
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.03);
          border: 3px solid #FFFFFF;
        }

        .icon-search-plus {
          position: absolute;
          bottom: 10px;
          right: 8px;
          color: #FFFFFF;
        }
      }
    }
  }
}

/*
* TABLET - COMPUTER
*/
@media (min-width: @tabletBreakpoint) and (max-width: @computerBreakpoint) {
  .ui.celled.basic.table.standard tr td {
    padding: 22px !important;
  }

  /*
  * SUBSCRIPTIONS
  */
  .ui.celled.basic.table.standard.subscriptions {
    thead tr th { padding: 22px; }
  }
}

/*
* TABLET UP
*/
@media (min-width: @tabletBreakpoint) {

  .preview-container {
    padding-bottom: 50px;

    .report-date { display: none; }
  }

  /*
  * STANDARD
  */
  .ui.celled.basic.table.standard {

    // show headers for tablet
    thead { display: table-header-group; }
  }

  /*
  * REPORTS
  */
  .ui.table.basic.standard.reports {

    tr {
      td {
        .mobile-heading { display: none; }

        &.subscriptions,
        &.download {
          padding: 22px 42px;
        }
      } // td
    } // tr

  } // reports

  .ui.celled.basic.table.bin-alerts-table td {
    padding: 17px !important;
    position: relative !important;

    &.thumb-cell {
      display: inline-block !important;
      width: 100px !important;
    }

    &.bin-alerts-table__address {
      display: inline-block !important;
      width: 60% !important;
      font-size: 12px !important;
      color: #797979;
      a {
        font-size: 14px;
        color: #424852;
      }
    }


    &.bin-alerts-table__waste-stream,
    &.bin-alerts-table__time {
      min-width: initial !important;
      display: inline-block !important;
      width: 50% !important;
      height: 58px !important;
      padding: 0px 17px !important;
      font-size: 12px;
      color: #797979;
      border-top: 1px solid rgba(151, 151, 151, 0.1) !important;
    }

    &.bin-alerts-table__time {
      padding-top: 17px !important;
      text-align: right;
      padding-right: 30px !important;
    }

    // Responsive helping styles

    &.bin-alerts-table__address .show-on-mobile {
      display:block !important;
      margin-left: -5px !important;
    }

    &.show-on-desktop {
      display:none !important;
    }

    .responsive-break {
      display: block !important;
    }

  }
}

@media (min-width: @tabletBreakpoint) {
  .bin-alerts-table__address a {
    color: #424852;
    &:hover {
      color: #424852;
    }
  }
}

/*
*   USER MANAGEMENT
*/

@media (max-width: @tabletBreakpoint) {

  .ui.celled.basic.table.user-management tr {
    background: #FCFCFC !important;
    position: relative !important;

    td {
      padding: 17px !important;

      &.first-name,
      &.last-name {
        font-weight: bold;
        font-size: 16px;
        display: inline-block !important;
        padding-bottom: 5px !important;
      }

      &.first-name {
        padding-right: 0 !important;
      }

      &.last-name {
        padding-left: 4px !important;
      }

      &.email {
        font-size: 16px;
        padding-top: 0 !important;
      }

      &.type, &.status {
        font-size: 12px;
        display: inline-block !important;
        width: 50% !important;
        border-top: 1px solid rgba(151, 151, 151, 0.1) !important;
      }

      &.type {
        color: #797979;
      }

      &.status {
        text-align: right !important;

        img {
          position: absolute;
          top: 35px;
          right: 15px
        }
      }
    }
  }
}


// INVOICES
.user-invoices-table,
.invoices-table {
  .selectable .invoice-file {
    &>:hover {
      cursor: default;
    }
  }
  .heading {
    margin-bottom: 30px;
    display: block;
    .timestamp {
      font-size: 1.5em;
      color: #0C5374;
      margin-right: 30px;
    }
    .download-all-link {
      font-size: 1.15em;
      font-weight: bold;
      color: #0C5374;
      text-decoration: underline;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .delete-invoice-wrapper {
    width: 108px;
  }
  .invoice-file > td:nth-child(1) {
    width: 60%;
  }

  td {
    padding: 24px !important;
  }


}
// Overrides until we have new styles accross the app
.user-invoices-table,
.invoice-table__header {
  .time-period__column {
    width: 170px;
  }
  .chevron__column {
    width: 84px;
  }
  .heading {
    margin-bottom: 0px;
    .timestamp {
      font-size: 1em;
      color: black;
    }
  }
}
.user-invoices-table {
  .invoice-number__column {
    width: calc(100% - 170px - 84px);
  }
}

.ui.table td.chevron__column {
  text-align: center;
}
.invoice-table__outer {
  margin-bottom: 0 !important;
}
.invoice-accordion {
  tr {
    &:hover {
      background: red !important;
    }
  }

  .invoice-file-list {
    .invoice-file {
      .actions-cell {
        padding: 24px !important;
      }
    }
  }
}
@media (min-width: 992px) {
  .user-invoices-table,
  .invoice-table__header {
    .time-period__column {
      width: 200px;
    }
    .chevron__column {
      width: 84px;
    }
  }
  .user-invoices-table {
    .invoice-number__column {
      width: calc(100% - 200px - 84px);
    }
  }
}
