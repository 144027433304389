
/*******************************
            Header
*******************************/

.account-edit .timestamp {
  display: inline-block;
  margin-right: 0;
}

.ui.header {
  color: #0095e3;

  &.form-header {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

.ui.grid.page-header {
  margin: 0 0 50px 0;
  border-bottom: 1px solid #E3E3E3;


  & > .row {
    margin-top: 40px;
    padding-bottom: 40px;
    border-bottom: 1px solid rgba(151, 151, 151, 0.1);
  }

  [class*="left floated"].column {
    padding-left: 0;
  }

  [class*="right floated"].column {
    padding-right: 0;
  }
}

.ui.grid.multi-header {
  margin: 0 0 50px 0;

  &.account-management {
    > .row {
      padding-bottom: 0;
      border-bottom: none;
    }

    a.remove,
    button.remove {
      color: #008DD6;
    }
  }

  & > .row {
    margin-top: 80px;
    padding-bottom: 40px;
    border-bottom: 1px solid rgba(151, 151, 151, 0.1);
  }

  [class*="left floated"].column {
    padding-left: 0;
  }

  [class*="right floated"].column {
    padding-right: 0;
    margin-top: 25px;
    padding-left: 0;
    line-height: 57px;
  }
}

.ui.label.status {
  border: 1px solid rgba(151, 151, 151, 0.25);
  border-radius: 100px;
  background: none;
  color: #424852;
  font-weight: bold;
  font-size: 16px;
  padding: 15px 25px;
  margin-right: 20px;

  .label {
    margin-right: 12px;
  }
}

.timestamp {
  font-size: 12px;
  color: #424852;
  margin-right: 20px;
}

a.remove,
button.remove, .ui.button.remove, button.invite,
.ui.button.remove, .ui.button.invite {
  font-size: 14px;
  color: #FF3F3F;
  font-weight: bold;
  text-decoration: underline;
  padding: 10px 0 10px 20px;
  border-right: none;
  border-top: none;
  border-bottom: none;
  border-left: 1px solid rgba(151, 151, 151, 0.1);
  background: none;

  &:hover {
    cursor: pointer;
  }

  &:hover,
  &:active {
    text-decoration: none;
    color: lighten(#FF3F3F, 5%);
    outline: none;
  }
}

button.invite, .ui.button.invite {
  color: #78BE1F;

  &:hover,
  &:active {
    color: lighten(#78BE1F, 5%);
  }
}

@media (max-width: @tabletBreakpoint) {
  .ui.grid.multi-header.account-management {
    a.remove,
    button.remove {
      float: left;
    }
  }

  h1.ui.header {
    font-size: 30px;
  }

  .ui.grid.page-header [class*="right floated"].column { display: none; }

  .ui.grid.page-header {
    &.alerts-header > .row {
      padding-bottom: 10px !important;
      padding-top: 50px !important;
      border: none !important;
    }
  }

  .ui.label.status {
    padding: 0;
    border: none;
  }

  a.remove,
  button.remove {
    padding: 0;
    float: right;
    border-left: none;
  }

  .ui.grid.multi-header {
    & >.row {
      border-bottom: 0;
      padding-bottom: 0;
      margin-top: 50px;
    }

    [class*="right floated"].column {
      border-top: 1px solid rgba(151,151,151,.1);
      border-bottom: 1px solid rgba(151,151,151,.1);
    }
  }

  .timestamp { display: none; }
}

@media (min-width: @tabletBreakpoint) {
  .ui.header {

    &.form-header {
      margin-top: 150px;
      margin-bottom: 80px;
    }
  }

  .ui.grid.multi-header {
    [class*="right floated"].column {
      text-align: right;
      margin-top: 0;
    }
  }
}
