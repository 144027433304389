
/*******************************
             Modal
*******************************/

.modals.dimmer .ui.scrolling.modal.image-modal { margin: 0 !important; }

// image modal
.ui.small.modal.image-modal {
  max-width: none;
  width: 100%;
  height: 100% !important;
  margin: 0 !important;
  top: 0;
  left: 0;

  & > .content {
    display: table;
    height: calc(~"100vh - 150px");

    & > .image {
      border: 7px solid #FFFFFF;
    }

    // slideshow
    .bin-alert-lightbox {
      width: 100%;
      height: 100%;
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;

      .btn-wrapper {
        display: flex;
        align-items: center;

        & > button {
          background: transparent;
          border: none;
          color: #D0D0D0;
          transition: color .4s;
          cursor: pointer;
          &:hover {
            color: white;
          }

          &:focus {
            outline: none;
          }
        }

        & > button:disabled,
        & > button[disabled] {
          cursor: not-allowed;
          opacity: 0.4;

          &:hover {
            color: #D0D0D0;
          }
        }
      }

      .image-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        img {
          width: 100%;
          max-width: 1300px;
        }
      }

    }
  }

  .header {
    padding: 1em !important;
    background: rgba(0, 0, 0, 0.9);

    .ui.label {
      background: none;
      padding: 11px 14px;
      color: #FFFFFF;
      font-size: 14px;
      border-radius: 100px;
      font-family: HelveticaNeue;
      font-weight: 400;
      border: 1px solid rgba(255, 255, 255, 0.25);
    }
  }

  .icon-cancel {
    right: 25px;
    top: 25px;
    font-size: 1.25em;
  }

  .close {
    position: relative;
    float: right;
    width: 20px;
    margin-top: 8px;
    color: #D0D0D0;

    &:hover {
      cursor: pointer;
      color: #FFFFFF;
    }
  }

} // image modal

@media (max-width: @tabletBreakpoint) {

  // image modal
  .ui.small.modal.image-modal {
    & > .content {
      padding: 0 !important;

      // slideshow
      .bin-alert-lightbox {
        .btn-wrapper {
          position: absolute;
          z-index: 99;
          background: rgba(0, 0, 0, 0.7);

          &:first-child {
            left: 0;
          }

          &:last-child {
            right: 0;
          }

          & > button i.icon {
            width: 0.8em;
          }
        }
      }
    }
  }
}

// general modal
.ui.modal {
  height: auto !important;

  &.small {
    max-width: 600px;
  }

  // close icon
  .icon-cancel {
    position: absolute;
    color: #fff;
    right: 15px;
    top: 15px;
    margin-right: 0;
    cursor: pointer;
  }

  button.cancel {
    background: none;
    border: none;
    cursor: pointer;
  }

  & > .header {
    padding-bottom: 20px;
  }

  & > .content {

    & > .description {
      padding: 40px;
    }
  }

  & > .actions {

    .cancel {
      font-size: 16px;
      color: #616161;
      text-decoration: underline;
      margin-right: 25px;

      &:hover {
        text-decoration: none;
      }
    }

    button:last-child {
      margin-right: 0;
    }
  }
}

// basic
.ui.basic.modal {
  .ui.header {
    padding-left: 0;
    padding-right: 0;
  }

  & > .actions {
    border-top: none;
  }
}

.invoice-upload-modal {
  #invoice-period {
    margin: 0 0 30px !important;
    .menu.transition {
      height: 300px;
    }
    .ui.selection.dropdown {
      color: #004b6d;
    }
  }
  #select-file {
    margin: 0 6px;
    .dropzone {
      width: 100%;
      padding: 25px 25px 15px;
      background: #F8F8F8;
      border: 2px dashed #d8d8d8;
      border-radius: 5px;
      font-size: 1.15em;
      font-weight: bold;
      text-align: center;
      p {
        margin-bottom: 5px;
      }
      .first-p,
      .second-p {
        color: #d8d8d8;
      }
      .third-p {
        color: #004b6d;
        text-decoration: underline;
      }
      &:hover {
        cursor: pointer;
        border: 2px dashed #afafaf;
        .first-p,
        .second-p {
          color: #afafaf;
        }
      }
    }
  }
  .invoice-buffer {
    margin: 0 1em;
    padding: 20px 0;
    border-bottom: 1px solid #eeeeee;
    font-size: 1.25em;
    font-weight: bold;
    &.first-element {
      border-top: 1px solid #eeeeee;
      margin-top: 24px;
    }
    &.last-element {
      margin-bottom: 24px;
    }
    & > .name-text {
      color: #616161;
      display: inline;
    }
    & > .remove-link {
      color: red;
      font-size: 14px;
      font-weight: bold;
      text-decoration: underline;
      color: #ff3f3f !important;
      display: block;
      float: right;
      &:hover {
        cursor: pointer;
      }
    }
  }

}
