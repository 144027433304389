
/*******************************
             Form
*******************************/

.ui.form {
  .field>label {
    display: inline-block;
  }

  [class*="equal width"].fields,
  .fields {
    & >.field {
      margin-bottom: 30px;
    }
  }

  .form-footer {
    margin-bottom: 50px;
    text-align: center;
    margin-top: 20px;

    button {
      width: 100%;
    }

    a {
      color: #616161;
      font-size: 16px;
      text-decoration: underline;
      line-height: 66px;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }
}

@media (min-width: @tabletBreakpoint) {
  .ui.form {

    [class*="equal width"].fields,
    .fields {
      margin-bottom: 60px;

      & >.field {
        margin-bottom: 0;
      }

      & >.field.half-flex {
        max-width: 368.5px;
      }
    }

    .form-footer {
      margin-bottom: 80px;
      text-align: right;
      margin-top: 0;

      button {
        width: initial;
      }

      a {
        margin-right: 40px;
      }
    }
  }
}

@media (min-width: @computerBreakpoint) {
  .ui.form {

    [class*="equal width"].fields,
    .fields {
      & >.field.half-flex {
        max-width: 477px;
      }
    }
  }
}
