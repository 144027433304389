
.collapsible-item-header {
  display: grid;
  padding: 10px;
  grid-template-columns: 2fr 0fr 0fr;
  justify-content: center;
  align-items: center;
  border: 1px solid #d9d9d9;
  gap: 15px;
  margin: 10px 0;
  margin-bottom: 0;
  cursor: pointer;
}

.collapsible-item:first-child {
  margin-top: 100px;
}

.collapsible-item-header label {
  display: flex;
  gap: 10px;
  font-size: 20px;
  font-weight: 700;
}

.collapsible-item-header button {
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 5px;
  gap: 5px;
  height: 54px;
  border-radius: 50px;
  border: 1px solid #d9d9d9;
  width: 150px;
}
.collapsible-item-header-selected {
  background: #004b6d;
}

.collapsible-item-header-selected label {
  color: #fff;
}

.collapsible-item-header-selected button {
  background: #fff;
}

.collapsible-item-section {
    margin-bottom: 10px;
    padding: 10px;
}

.collapsible-item-section  h4 {
    font-size: 20px;
    font-weight: 800;
    color: #004B6D;
}

.collapsible-item-section .collapsible-card {
    background-color: #fff;
    margin: 10px 0;
    padding: 15px 0;
}
.collapsible-item-section .collapsible-card-item {
    display: flex;
    flex-direction: column;
    padding: 14px 20px;
}

.collapsible-item-section .collapsible-card-item h6 {
    font-weight: 800;
    font-size: 14px;
    color: #004B6D;
    padding: 0;
    margin: 0;
}

.collapsible-item-section .collapsible-card-item label {
  font-weight: 400;
  font-size: 14px;
  color: #004B6D;
}

.collapsible-item-section .collapsible-card-item label:last-child {
    color: #0D2B3A;
    margin-top: 10px;
}

.none-text {
  color: #BCBCBC !important;
  margin-top: 10px;
  font-weight: 400 !important;
}

.image-warning-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
}

.image-warning-box h4 {
  color: #F62E2E;
  font-size: 24px;
  line-height: 28.8px;
  text-align: center;
}

.image-warning-box button {
  background-color: #fff;
  border: 1px solid #F62E2E;
  box-shadow: none;
  padding: 10px;
  border-radius: 5px;
  color: #F62E2E;
  width: 155px;
  cursor: pointer;
}