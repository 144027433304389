.value-text-Assigned {
    color: #1B4A6B !important;
}

.value-text-Exception {
    color: red !important;
}

.value-status-text {
    font-weight: 800 !important;
}

.value-status-text label {
    font-weight: 100 !important;
}